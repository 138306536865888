import React from 'react'


import Tooltip from '@mui/material/Tooltip'

type MeritBadgeProps = {
    merit: number
}

export default function MeritBadge({merit}: MeritBadgeProps) {
    return <Tooltip title="Merit" placement="top" enterDelay={500} leaveDelay={200}>
        <div className="meritBanner">{merit}</div>
    </Tooltip>
}