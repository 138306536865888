import React, {useState, useContext, useEffect, useMemo} from 'react'

import Input from '@mui/material/Input'
import Button from '@mui/material/Button'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import CompareIcon from '@mui/icons-material/Compare'
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'

import * as Yup from "yup"
import {withFormik} from "formik/dist/index"

import TagField, {tagStructure, updateTagsArray, updateTags} from "../../Components/Forms/TagField"
import {tagDataForCard} from "../../Structures/Tag"
import CardBackSelector from "../CardBackSelector"
import SnapFormContext from "./Contexts/SnapForm.context"
import {SnapClass as Snap} from '../../database/models/Snap'
import AppContext from '../../Contexts/App.context'
import ConfirmContext from "../../Contexts/Confirm.context"
import imageCompression from 'browser-image-compression'

function SnapFormDialog() {
    const {
        open,
        snap,
        unsavedChanges,
        cardBack,
        setCardBack,
        setUnsavedChanges,
        closeDialogue,
        onSuccess
    } = useContext(SnapFormContext)

    const {tags} = useContext(AppContext)

    const {confirm} = useContext(ConfirmContext)

    const [doClose, setDoClose] = useState(false)

    // Listen for close 'cue' to then do close with access to context state
    useEffect(() => {
        if (doClose) {
            setDoClose(false);
            handleClose();
        }
    }, [doClose]);

    const onClose = () => setDoClose(true);

    // Close dialog box
    const handleClose = () => {
        // Check for unsaved changes before closing
        if (unsavedChanges) {
            confirm(() => {
                closeDialogue()
            }, 'You have unsaved changes. Are you sure you want to close without saving?', 'Unsaved Changes');

        } else {
            closeDialogue()
        }
    };

    let title = snap ? 'Update Snap' : 'New Snap'
    const classes = [
        'NoteDialog'
    ]

    // Dialogue extends Modal, so we can use Modal props (https://v4.mui.com/api/modal/)
    return (
        <>
            <Dialog
                className="NoteDialogRoot DialogOverride SnapDialog"
                open={open}
                onClose={onClose}
                classes={{
                    'paper': classes.join(' '),
                    'container': 'NoteFormContainer'
                }}
                disablePortal
                BackdropProps={{
                    'children': open &&
                    <CardBackSelector
                        initialCardBack={cardBack}
                        autoDraw={!snap}
                        setCardBack={setCardBack}
                        setUnsavedChanges={setUnsavedChanges}
                    />
                }}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {/*Send closeDialog prop to allow dialog state to be updated from the deeper*/}
                    <SnapForm
                        closeDialog={onClose}
                        onSubmit={onSuccess}
                        snap={snap}
                        tagsData={tags.slice()}
                        setUnsavedChanges={setUnsavedChanges}
                        cardBack={cardBack}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}

// Formik HTML structure
const SnapFormHtml = props => {
    // Props
    const {
        snap,
        values,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isValid,
        isSubmitting,
        cardBack,
        setUnsavedChanges
    } = props

    const backTouched = snap?.back && snap.back !== cardBack
    
    const isFormTouched = () => {
        return backTouched || Object.keys(touched).length !== 0
    }

    const onImageChange = (event) => {
        const file = event.target.files[0]

        const options = {
            maxSizeMB: 0.1,
            maxWidthOrHeight: 1600,
            useWebWorker: true,
        }

        imageCompression(file, options).then(compressedFile => {
            onChange('image', compressedFile)

        }).catch(function (error) {
            console.log(error.message);
        })
    }
    
    let buttonText = snap ? 'Update' : 'Create'

    let tagsData = props.tagsData.map(tag => tagStructure(tag))

    let unsavedChanges = (bool) => {
        // Update state of parent when changes are unsaved
        setUnsavedChanges(isValid || bool)
    }

    // Custom wrapper for setFieldValue to allow additional functions to be performed on update
    let onChange = (id, value) => {
        setFieldTouched(id, true, true)
        // Default behavior of onChange
        setFieldValue(id, value)

        unsavedChanges()
    }

    // Update or add Tags to be updated/saved
    let updateTagsData = (tag) => {
        setFieldValue('updatedTagsData', updateTagsArray(values.updatedTagsData, tag))
        setFieldTouched('updatedTagsData')

        unsavedChanges(true)
    }

    const renderImageUploadField = useMemo(() => {
        if (values.image) {
            const src = URL.createObjectURL(values.image)

            return (
                <div className='SnapImageUploadFrame'>
                    <div className='SnapImageUpload' style={{backgroundImage: `url(${src})`}}/>
                    <div className='SnapImageUploadEdit'>
                        <CompareIcon />
                        <p>Change Image</p>
                    </div>
                </div>
            )
        }

        return <div className='SnapUploadField'>
            <AddAPhotoIcon />
            <p>Add Image</p>
        </div>
    }, [values.image])

    return (
        <form onSubmit={handleSubmit}>
            <label htmlFor="upload_snap_image">
                {renderImageUploadField}
            </label>

            <input
                id="upload_snap_image"    
                type="file"
                accept="image/*"
                onChange={onImageChange}
                style={{display: 'none'}}
            />

            <FormControl fullWidth error={errors.title && touched.title}>
                <Input
                    id="title"
                    type="text"
                    value={values.title}
                    placeholder="Label this Snap..."
                    autoComplete="off"
                    onChange={(event) => onChange('title', event.target.value)}
                    autoFocus
                    disableUnderline={true}
                />
                <FormHelperText className="fieldDesc">
                    {errors.title && touched.title ? errors.title : null}
                </FormHelperText>
            </FormControl>

            <TagField
                id="tags"
                description="Tags"
                onChange={onChange}
                tagsData={tagsData}
                updateTagsData={updateTagsData}
                value={values.tags}
            />

            <DialogActions>
                <Button disabled={isSubmitting} onClick={props.closeDialog} color='inherit'>
                    Cancel
                </Button>
                <Button type="submit" color="primary" variant="outlined" disabled={isSubmitting || !isValid || !isFormTouched()}>
                    {buttonText}
                </Button>
            </DialogActions>
        </form>
    );
};

// Use Formik on some pre-defined Formik HTML
const SnapFormHandling = withFormik({
    // Initial form values (handles field population when updating Snap)
    mapPropsToValues: props => ({
        title: props.snap ? props.snap.title : '',
        image: props.snap ? props.snap.image : '',
        tags: props.snap ? tagDataForCard(props.tagsData, props.snap.tags).map(tag => tagStructure(tag)) : [],
        updatedTagsData: [],
        cardBack: props.snap && props.snap.back ? props.snap.back : 'default',
    }),
    validationSchema: Yup.object().shape({
        title: Yup.string()
            .required('Title is required!')
            .max(75, 'That looks a little long...')
    }),
    handleSubmit: (values, formikBag) => {
        // Supplied journey or new one
        const snap = formikBag.props.snap || new Snap()

        // Update data
        snap.title = values.title
        snap.image = values.image
        // A Snap only saves tag strings, so prepare data for that (essentially storing 'IDs')
        snap.tags = values.tags ? values.tags.map(function(tag) {
            // Convert objects into strings
            return tag.value;
        }) : []

        // Card Back
        snap.back = formikBag.props.cardBack;

        // Save updates to Tags
        updateTags(values.updatedTagsData);

        // Execute passed through onSuccess function
        formikBag.props.onSubmit(snap).then(() => {
            formikBag.resetForm()
            formikBag.setSubmitting(false)

            // Reset unsavedChanges state in parent to prevent confirm dialogue on save
            formikBag.props.setUnsavedChanges(false);

            // Close the form using supplied function
            formikBag.props.closeDialog() // Maps to handleClose() in SnapDialog
        }).catch(function (err) {
            console.log(err)
        })
    },

    displayName: 'NoteForm'
});

// Use Formik on some pre-defined Formik HTML
const SnapForm = SnapFormHandling(SnapFormHtml);

export default SnapFormDialog;