import { Note } from "../database/models/Note";
import { Snap } from "../database/models/Snap";

export function getDraggableIdString(card: Note | Snap): string {
    return `${card.type}-${card.id}`
}

export function getTypeAndIdFromString(string: string): {id: number, type: string} {
    // This function can always return id and type.

    // Now: If given the modern string 'type-id', we split on '-' and return the value
    // Old: Back. Compat. If given 'id' we return the id and default to 'note' type
    const split = string.split('-')

    if (split.length === 1) {
        return {type: 'note', id: parseInt(split[0])}
    }

    return {type: split[0], id: parseInt(split[1])}
}

export function cardMatchesCardReference(card: Note | Snap, cardReference: string): boolean {
    const {id, type} = getTypeAndIdFromString(cardReference)
    // atch where id and type are the same
    return card.id === id && card.type === type
}

// Is card in array of references to cards
// eg: [1,2,3,4,'snap-20', 'note-11']
export const cardInReferenceArray = (card, searchArray): boolean => {
    // Legacy handling of old ID only data - From when we stored an array of Card.ID only
    // Card ID in Column cards array - First do a sweep for old Card.ID storage
    // Return false for finding this card as an archaic ID
    if (card.type === 'note' && searchArray.indexOf(card.ID) > -1) return true

    const columnCardReferenceIndex = searchArray.findIndex((cardReference) => {
        // Guard from old ID data not being a string
        if (typeof cardReference !== 'string') return false

        return cardMatchesCardReference(card, cardReference)
    })

    return columnCardReferenceIndex > -1
}