import React, {useState, useEffect} from 'react';

// Material Design Components
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';

import {sameDay, dateFormat} from "../Helpers/dateHelper";
import {EmptyMessage} from "../Helpers/tools";
import {isBottom} from "../Helpers/cardsScrollLoading";
import MarkdownInterpreter from "../Helpers/MarkdownInterpreter";

import { FixedSizeList as VirtualizedList } from 'react-window';
import Card from './Card';
import Snap from './Snap'
import CardOrnate from './CardOrnate';
import { getDraggableIdString } from '../Helpers/cardReferences';

// The number of cards to load initially on a given screen.
// The number of cards to additionally load when more cards are sought.
export const cardLoadIncrement = 50;

function Cards(props) {
    const [range, setRange] = useState(cardLoadIncrement);

    useEffect(() => {
        const handleScroll = (e) => {
            // Checks that the page has scrolled to the bottom
            if (isBottom(e, true)) { loadMoreCards(); }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [range]);

    // Loads portions of cards to aid load speed
    const loadMoreCards = () => {
        setRange(range + cardLoadIncrement)
    };

    // Render Cards and Snaps
    // ---
    // Snaps and Cards are both handled
    const renderCards = (cards) => {
        let dateSeparators = false;
        let collection = null;

        // Additional logic if collection supplied
        if (props.collection) {
            collection = props.collection;
            // Check if collection orders by date
            dateSeparators = collection.orders.reduce(function(hasDate, order) {
                return hasDate || order.comparison === 'date';
            }, false);
        }

        cards = cards.map((card, index, array) => {
            let date = new Date(card.created_at);
            // Determine whether to render date separator
            let showDate = dateSeparators &&
                (
                    index === 0 || // First card in array
                    !sameDay(new Date(array[index - 1].created_at), date) // Different day to previous
                );

            // We still want to render all 'Cards' but now have a new type of Card
            // Rudimentary comparison of Card and Snap
            const cardRender = card.type === 'snap' ? <Snap key={getDraggableIdString(card)} snap={card}/> : <Card key={getDraggableIdString(card)} card={card} tags={props.tags} collection={collection}/>

            return (
                <React.Fragment key={index}>
                    { showDate &&
                    <div className="divider-heading"><span>
                        {dateFormat(date)}
                    </span></div>
                    }
                    { cardRender }
                </React.Fragment>
            );
        });

        return cards;
    };

    return (
        <div className="Cards">
            <Grid container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="flex-start"
            >
                {renderCards(props.cards.slice(0, range))}
            </Grid>
        </div>
    );
}

export function Content(props) {
    return (
        <CardContent className="Content">
            <MarkdownInterpreter markdown={props.content}/>
        </CardContent>
    );
}

export function Tags(props) {
    let tags = (
        <ul>{props.tags.map((tag, index) => <li key={index} style={{backgroundColor: tag.color}}>{tag.tag}</li>)}</ul>
    );

    return (
        <div className={"Tags" + (props.cardExpanded ? " cardExpanded" : "")}>
            {props.tags.length ? tags : ''}
        </div>
    );
}

// Cards output for display purposes only, does not maintain functionality
export function CardsOrnate(props) {
    const renderOrnateCard = ({index, style, data}) => {
        const gutter = 12;
        const card = data[index];
        return (
            <div
                style={{
                    ...style,
                    top: style.top + gutter,
                }}
                className={'stackedOrnateCard'}>
                <CardOrnate key={card.id} card={card} onClick={props.onClick}/>
            </div>
        )
    };

    return (
        <div className="Cards CardsOrnate">
            <Grid container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="flex-start"
            >
                { props.cards.length > 0 &&
                    <VirtualizedList
                        className="VirtualizedCardOrnateList"
                        height={300}
                        itemCount={props.cards.length}
                        itemData={props.cards.slice()}
                        itemSize={75}
                        width={'100%'}
                    >
                        { renderOrnateCard }
                    </VirtualizedList>
                }
                { props.cards.length === 0 &&
                    <EmptyMessage />
                }

            </Grid>
        </div>
    );
}

export default Cards;