import React, {useState, useContext} from 'react';

import {cardLoadIncrement} from './Cards';
import Card from './Card';
import Snap from './Snap';
import {Droppable, Draggable} from "react-beautiful-dnd";
import Input from '@mui/material/Input';

import {isBottom} from '../Helpers/cardsScrollLoading';
import AppContext from '../Contexts/App.context';
import { getDraggableIdString } from '../Helpers/cardReferences';

// Array mappable draggable Card wrapper
//
// @param card {Card} A Card object
// @param index {Int} The array mapping index
export function draggableCard(card, index) {
    const {tags} = useContext(AppContext)
    const cardId = card.type === 'note' ? card.ID : card.id
    // Check Card object
    if (!cardId) {return false}

    const renderCard = (provided, snapshot) => {
        if (card.type === 'note') {
            return <Card key={card.ID} card={card} tags={tags} dragHandleProps={provided.dragHandleProps} isDragging={snapshot.isDragging} collection={null}/>
        }
        if (card.type === 'snap') {
            return <Snap snap={card} dragHandleProps={provided.dragHandleProps} isDragging={snapshot.isDragging}/>
        }
    }

    const draggableId = getDraggableIdString(card)

    return (
        <Draggable draggableId={draggableId} index={index} key={'draggableCard' + draggableId}>
            {(provided, snapshot) => (
                <div className={"draggableCard"} ref={provided.innerRef} {...provided.draggableProps}>
                    { renderCard(provided, snapshot) }
                </div>
            )}
        </Draggable>
    );
}

function CardsDrawer(props) {
    const [range, setRange] = useState(cardLoadIncrement);

    // Loads portions of cards to aid load speed
    const loadMoreCards = () => {
        setRange(range + cardLoadIncrement);
    };

    const handleScroll = (e) => {
        // Checks that the page has scrolled to the bottom
        if (isBottom(e)) { loadMoreCards() }
    };

    const draggableCardWrapper = (card, index) => {
        return draggableCard(card, index);
    };

    return (
        <React.Fragment>
            <Input
                id="globalSearch"
                className={"CardsDrawerSearchField" + (props.open ? ' open' : '')}
                type="text"
                placeholder="Search..."
                onChange={(event) => props.searchCards(event.target.value)}
            />
            <Droppable droppableId="cardsList" type='CARD'>
                {(provided, snapshot) => (
                    // Use snapshot.draggingFromThisWith to add class, used to toggle overflow in _cardDrawer.scss
                    // Overflow is toggled to allow scrolling AND to prevent card from being hidden when dragged outside the drawer
                    // (https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/droppable.md)
                    <div className={"Cards CardsDrawer" + (props.open ? ' open' : '') + (snapshot.draggingFromThisWith ? ' draggingFrom' : '')} onScroll={handleScroll} ref={provided.innerRef} {...provided.droppableProps}>
                        { props.open &&
                            props.cards.slice(0, range).map(draggableCardWrapper)}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </React.Fragment>
    );
}

export default CardsDrawer;