import React, {useContext} from 'react';
import Tooltip from '@mui/material/Tooltip';
import InfoDialogContext from '../Contexts/InfoDialog.context';
import IntroductionContent from '../Components/Content/IntroductionContent';
import { useEasterEgg } from '../Helpers/easterEggs';

function Logo() {
    const {infoDialog} = useContext(InfoDialogContext)
    const easterEgg = useEasterEgg()

    const classes = [
        'logo',
        easterEgg === 'noto-birthday' ? 'cargo' : null,
        easterEgg === 'xmas' ? 'xmas' : null,
    ]

    return (
        <Tooltip title="About Noto" placement="bottom" enterDelay={500} leaveDelay={200}>
            <div className={classes.join(' ')} onClick={() => infoDialog(<IntroductionContent/>, "Let's go!")}/>
        </Tooltip>
    )
}

export default Logo;