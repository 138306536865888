import { Journey } from "../database/models/Journey";
import { Note } from "../database/models/Note";
import { Snap } from "../database/models/Snap";
import {Filter, FilterType, filterCards, filterJourneyCards} from "./Filter";
import {OrderType} from "./Order";
import {Order, orderCards} from "./Order";
import localforage from "localforage";

export interface Collection {
    ID?: number
    display: boolean
    title: string
    filters: FilterType[]
    orders: OrderType[]
    inclusive: boolean
}

// <editor-fold desc="Collection">
// A Collection represents the structure for a combination of filters and orders,
// it is to be used to filter/order cards
// One Collection per page of Cards
//
// @param title {string}
// @param filters {array} of Filter objects
// @param orders {array} of Order objects
export class CollectionClass {
    ID?: number
    display: boolean
    title: string
    filters: FilterType[]
    orders: OrderType[]
    inclusive: boolean

    constructor(title = null, filters = [], orders = []) {
        this.ID = null;
        this.display = true;

        this.title = title;
        this.filters = filters;
        this.orders = orders;

        this.inclusive = true;
    }
}

type FilterableObject = Collection | Journey

// Use a Collection to filter/order cards by its criteria
export function collectCards(cards: Array<Note | Snap>, filterableObject: FilterableObject): Array<Note | Snap> {
    // TODO: Don't love this...
    const isJourney = 'id' in filterableObject

    let filteredCards = cards.slice()
    // New variable to prevent state mutation
    let object = Object.assign(filterableObject);

    // Make Filter class from data
    if (object?.filters) {
        object.filters = dataToFilter(object.filters);
        // Apply filters
        filteredCards = isJourney ? filterJourneyCards(filteredCards, object) : filterCards(filteredCards, object);
    }

    // Make Order class from data
    if (object?.orders) {
        const orders = dataToOrder(object.orders);
        // Apply orders
        filteredCards = orderCards(filteredCards, orders);
    }

    return filteredCards;
}
//</editor-fold>

//<editor-fold desc="Collection Collecting Helpers">

// Arrange filter data into object
// Returned object to be stored and also parsed into Filter class
export function newFilterData(property, value, evaluation, label) {
    return {
        property: property,
        value: value,
        evaluation: evaluation,
        label: label
    }
}

// Assign data to Filter class object
//
// @param data {array || object} Array of objects or just one
// (Must be able to map to Filter class object)
//
// returns Filter || Array (of Filters)
function dataToFilter(data) {
    // Array of filters
    if (Array.isArray(data)) {
        return data.map(function(filter) {
            return new Filter(filter.property, filter.value, filter.evaluation, filter.label);
        });
    }

    // Just one filter object
    if (typeof data === 'object') {
        return new Filter(data.property, data.value, data.evaluation, data.label);
    }
}

// Arrange order data into object
// Returned object to be stored and also parsed into Order class
export function newOrderData(property, ascending = false, comparison) {
    return {
        property: property,
        ascending: ascending,
        comparison: comparison
    }
}

// Assign data to Order class object
//
// @param data {array || object} Array of objects or just one
// (Must be able to map to Order class object)
//
// returns Order || Array (of Orders)
function dataToOrder(data) {
    // Array of orders
    if (Array.isArray(data)) {
        return data.map(function(order) {
            return new Order(order.property, order.ascending, order.comparison);
        });
    }

    // Just one order object
    if (typeof data === 'object') {
        return new Order(data.property, data.ascending, data.comparison);
    }
}
//</editor-fold>

//<editor-fold desc="Collection Management Helpers">

// Handle auto incrementing IDs
//
// @return {Promise -> Int} Evaluates to incremented ID
function generateID() {
    return localforage.getItem('collectionIdIncrementer').then(function (ID: number) {
        // Increment cardIdIncrementer and return
        return localforage.setItem('collectionIdIncrementer', ID + 1)
            .then((result) => {return result});

    }).catch(function(error) {
        console.log(error);
    });
}
//</editor-fold>

//<editor-fold desc="Collections">

// Recursively generate and set IDs where not set
//
// @param collections {Array}
//
// @returns Promise -> Array
function generateIDs(collections, i = 0) {
    // Return on last collection
    if (i === collections.length) { return Promise.resolve(collections); }

    // If no ID
    if (collections[i].ID === null) {
        // generate ID
        return generateID().then((id) => {
            collections[i].ID = id;
            // Move on to next iteration
            return generateIDs(collections, i + 1);
        });
    }
    // Move on to next iteration
    return generateIDs(collections, i + 1);
}

// Save Collections to storage with name
//
// @param collections {array} Array of Collection class objects to save
//
// @returns Promise
export function saveCollections(collections) {
    if (!Array.isArray(collections)) {
        throw new Error('Supplied parameter "collections" is not an array.');
    }
    
    return generateIDs(collections).then((collections) => {
        // Save
        return localforage.setItem('collections', collections).catch(function(err) {
            throw new Error(err);
        });
    });
}

// Get Collections from storage by name
//
// @returns Promise -> Array
export function loadCollections() {
    return localforage.getItem('collections').then((result) => {
        // Make sure an array is returned
        if (Array.isArray(result)) {
            return result.map(function(collection) {
                return Object.assign(new CollectionClass(), collection)
            });
        }
        return [];
    }).catch(function(err) {
        console.log(err);
        return [];
    });
}

// Delete
//
// @returns {Promise} Promise to delete the collection, returns collections
export function deleteCollection(id) {
    // Get existing collections
    return loadCollections().then(collections => {
        // Target index
        let targetCollection = collections.indexOf(
            // Find by ID
            collections.find((collection) => {
                return collection.ID === id;
            })
        );

        // If index found
        if (targetCollection > -1) {
            // Remove
            collections.splice(targetCollection, 1);

            // Set collections data to storage
            return saveCollections(collections);
        }

        throw new Error('Couldn\'t find Collection by ID to delete.');
    });
}
//</editor-fold>
//</editor-fold>