import React, {useRef, useMemo} from 'react';

// Material Design Components
import MDCCard from '@mui/material/Card';

// Custom Components
import {cardValueLabel} from "./CardValue";
import {Content} from "./Cards";
import { SnapImage, SnapTitle } from './Snap';

function CardOrnate({card, onClick, hidden}) {
    const selector = useRef(null);

    const flipped = card.private;
    const classes = [
        'Card',
        card.type === 'snap' ? 'Snap' : null,
        cardValueLabel(card.merit),
        flipped ? 'private' : null,
        card.retired_at ? 'retired' : null,
        card.back ? 'cardBack_' + card.back : null,
        hidden ? 'hidden' : null
    ];
    const ornateCardClasses = [
        'CardOrnate',
        flipped ? 'private' : null,
    ];

    const handleClick = () => {
        if (!onClick) return
        onClick(card)
    }

    const renderFace = useMemo(() => {
        if (card.type === 'snap') {
            // Don't fail if image is messed up (eg: set to null somehow - bad data)
            const src = card.image ? URL.createObjectURL(card.image) : null

            return <>
                <SnapImage src={src}/>
                <SnapTitle text={card.title}/>
            </>
        }

        if (card.type === 'note') return <Content content={card.content}/>
    }, [card.type, card.image, card.title, card.content])

    return (
        <div className={ornateCardClasses.join(' ')}>
            { !flipped &&
                <MDCCard
                    id={"Card_ID_" + card.id }
                    className={classes.join(' ') + ' face'}
                    ref={selector}
                    onClick={handleClick}
                >
                    { null !== card.retired_at && <div className="retiredBadge"/>}

                    { card.merit > 0 && <div className="meritBanner">{card.merit}</div>}
                    { renderFace }
                </MDCCard>
            }
            { flipped &&
                <MDCCard
                    id={"Card_ID_" + card.id }
                    className={classes.join(' ') + ' back'}
                    ref={selector}
                    onClick={handleClick}
                />
            }
        </div>
    );
}

export default CardOrnate;