// Search Card by a search term and return an array of Cards filtered by the term
//
// @param cards {Array} Array of Snaps + Notes
// @param term {String} Search term
//
// @returns {Array} Array of Snaps + Notes
export function searchCards(cards, term) {
    if (term && term !== '') {
        // Lowercase for case-insensitive comparisons
        term = term.toLowerCase();

        return cards.filter(function(card) {
            // Card filtering
            if (card.type === 'note') {
                // Lowercase content for case-insensitive comparisons
                return card.content.toLowerCase().includes(term) ||
                hasSearchTermTagMatch(card, term)

            // Snap filtering
            } else if (card.type === 'snap') {
                return card.title.toLowerCase().includes(term) ||
                hasSearchTermTagMatch(card, term)
            }
        });
    }

    return cards;
}

const hasSearchTermTagMatch = (array, term) => {
    // Reduce array of tags to bool (does a tag match the search term)
    return array.tags.reduce((accumulator, tag) => {
        // Map tags to lowercase for case-insensitive comparisons
        // Check for inclusion of search term
        return accumulator || tag.toLowerCase().includes(term);
    }, false)
}

// Search Collections by a search term and return an array of Collections filtered by the term
//
// @param cards {Array} Array of Collections
// @param term {String} Search term
//
// @returns {Array} Array of Collections
export function searchCollections(collections, term) {
    if (term && term !== '') {
        // Lowercase for case-insensitive comparisons
        term = term.toLowerCase();

        return collections.filter(function(collection) {
            // Lowercase content for case-insensitive comparisons
            return collection.title.toLowerCase().includes(term);
        });
    }

    return collections;
}

// Search Boards by a search term and return an array of Boards filtered by the term
//
// @param cards {Array} Array of Boards
// @param term {String} Search term
//
// @returns {Array} Array of Boards
export function searchBoards(boards, term) {
    if (term && term !== '') {
        // Lowercase for case-insensitive comparisons
        term = term.toLowerCase();

        return boards.filter(function(board) {
            // Lowercase content for case-insensitive comparisons
            return board.title.toLowerCase().includes(term);
        });
    }

    return boards;
}