import React, {useState, useContext, useEffect, useRef, useCallback, useMemo} from 'react';
import {debounce} from "lodash";

// Material Design Components
import Tooltip from '@mui/material/Tooltip';
import MDCCard from '@mui/material/Card';

// Custom Components
import {tagDataForCard} from "../Structures/Tag";
import AppContext from "../Contexts/App.context.js";
import {cardValueLabel} from "./CardValue";
import {notify} from "./Notice";
import {cardWearClass, cardWearVariantClass} from "../Helpers/cardWear";
import {Content} from "./Cards";
import ConfigContext from "../Contexts/Config.context";
import NoteFormContext from "./Forms/Contexts/NoteForm.context";
import ConfirmContext from '../Contexts/Confirm.context';
import CardActions from './CardFeatures/Actions';
import {expandDuration} from '../Helpers/constants';
import RetiredBadge from './CardFeatures/RetiredBadge';
import MeritBadge from './CardFeatures/MeritBadge';
import Flipper from './CardFeatures/Flipper';

function Card(props) {
    const {card} = props;
    const {popNoteFormDialog} = useContext(NoteFormContext);
    const {confirm} = useContext(ConfirmContext);

    // Register AppContext
    const {loadCards, linkedCard, updateLinkedCard, focusCard, updateFocusCard} = useContext(AppContext);
    // Register config context
    const {config} = useContext(ConfigContext);

    const [expanded, setExpanded] = useState(props.expanded || false);
    const [privateAnimating, setPrivateAnimating] = useState(props.expanded || false);
    const [merit, setMerit] = useState(card.merit);

    const selector = useRef(null);

    // Handle show/hide of additional Card options
    //
    // @param open {bool} Set to open? (defaults to null then and uses expanded state)
    const expandToggle = (open = null) => {
        // Handle case that open is the event from onClick
        if (open !== null && typeof(open) !== "boolean") {
            open = null;
        }

        setExpanded(null !== open ? open : !expanded);
    };

    const setFocus = (event = null) => {
        // Rules for allowing focus
        if (event && event.target) {
            // Prevent CardLink from triggering setFocus/Clear linked Card
            // if inDialog;
            // else we want it to focus a Card AND open a linkedCard
            if (event.target.closest('.CardLink') && props.inDialog) return;
            // - Disallow focus/clear linked Card if this is the LinkedCard
            // Todo: This also disallows closing a self linked Card
            if (linkedCard.card && linkedCard.card.id === card.id) return;
            // - No focus if click link
            if (event.target.nodeName === 'A') return;
            // - No focus if click inside actions
            if (event.target.closest('.CardActions')) return;
            // - No focus if selecting
            if (!!window.getSelection().toString()) return;
        }

        // When linked Card, clear it when clicking on the current focus Card
        if (linkedCard.card) {
            // Clear linked Card
            updateLinkedCard(linkedCard.card, true);
            // Todo: timeout function to clear the card? (duration of animation)
        }

        // Check context and catch Board drag'n'drop events and prevent them from activating a flip
        if (focusCard || props.isDragging) return;

        updateFocusCard(card);
    }

    // Retire the Card
    const toggleRetired = () => {
        card.toggleRetired();

        update(card).then(() => {
            let message = card.retired_at ? 'Card retired' : 'Card un-retired';
            notify(message);
        });
    };

    // Handle merit button on Card
    const incrementMerit = (n: number) => {
        // Do nothing if goes below 0
        if (merit + n < 0) return;

        setMerit(merit + n);
        debouncedUpdateMerit(merit + n);
    };

    // How does this work? See this article: https://www.developerway.com/posts/debouncing-in-react
    const updateMerit = useCallback((card) => update(card), [card]);

    const debouncedUpdateMerit = useMemo(() => {
        return debounce((newMerit) => {
            card.merit = newMerit;
            updateMerit(card)
        }, 500)
    }, [updateMerit]);

    // Keep state informed of merit changes that may have happened in a 'focusCard'
    useEffect(() => {
        setMerit(card.merit);
    }, [card.merit]);

    // Toggle privacy of Card
    const togglePrivate = () => {
        // Catch Board drag'n'drop events and prevent them from activating a flip
        if (props.isDragging) return;

        card.togglePrivate();

        // Handle animation of lifting the card
        setPrivateAnimating(true);
        setTimeout(() => {
            setPrivateAnimating(false);
        }, (expandDuration));


        update(card);
    };

    // Delete the card
    const deleteCard = () => {
        confirm(() => {
            updateFocusCard(null);
            updateLinkedCard(null);

            card.delete().then(() => {
                notify('Card deleted');
                loadCards();
            });
        }, 'Do you want to delete this card?', 'Delete Card');
    };

    // Updates; and optionally saves, changes to Card
    //
    // @param card {Object} Card object to update and re-render
    //
    // @returns Promise
    const update = (card) => {
        // Saving
        return card.save().then(() => {
            loadCards();
        });
    };

    const date = new Date(card.created_at);
    const content = card.content;
    const retired = null !== card.retired_at
    const tags = tagDataForCard(props.tags, card.tags);
    const classes = [
        'Card',
        cardValueLabel(merit),
        props.isDragging ? 'dragging' : null,
        card.private ? 'private' : null,
        retired ? 'retired' : null,
        expanded ? 'expanded' : null,
        card.back ? 'cardBack_' + card.back : null,
        config.cardWear ? cardWearClass(card.wear) : null,
        config.cardWear ? cardWearVariantClass(card.wear_variant) : null,
        // If this Card is focused, placeholder is output
        (!props.inDialog && focusCard && focusCard.type === 'note' && focusCard.ID === card.ID) ? 'hidden' : null
    ];

    return (
        <Tooltip
                title={!props.inDialog ? "Click to Focus" : ''}
                placement="top"
                enterDelay={3000}
                leaveDelay={200}
                enterNextDelay={6000}
            >
            <div
                {...props.draggableProps}
                {...props.dragHandleProps}
            >
                <Flipper
                    flipped={card.private}
                    flipAnimating={privateAnimating}
                    front={
                        <MDCCard
                            id={"Card_ID_" + card.ID }
                            className={classes.join(' ') + ' face'}
                            ref={selector}
                            onClick={setFocus}
                        >
                            { retired && <RetiredBadge /> }
                            { merit > 0 && <MeritBadge merit={merit}/> }

                            <Content content={content}/>

                            <CardActions
                                expanded={expanded}
                                tags={tags}
                                expandToggle={expandToggle}
                                date={date}
                                retired={retired}
                                deleteAction={deleteCard}
                                editAction={() => popNoteFormDialog(
                                    () => update,
                                    card,
                                    props.collection
                                )}
                                retireAction={toggleRetired}
                                privateAction={togglePrivate}
                                meritAction={incrementMerit}
                            />
                        </MDCCard>
                    }
                    back={
                        <MDCCard
                            id={"Card_ID_" + card.ID }
                            className={classes.join(' ') + ' back'}
                            ref={selector}
                            onClick={togglePrivate}
                        />
                    }
                />
            </div>
        </Tooltip>
    )
}

export default Card;