import { saveAs } from "file-saver"
import Dexie from 'dexie'
import {exportDB} from "dexie-export-import"
import {db} from '../database/db'

export async function exportAppData () {
    // Localforage
    const oldDb = await new Dexie('localforage').open()
    const localforageExportBlob = await exportDB(oldDb)
    const localforageExportText = await localforageExportBlob.text()
    const localforageDatabaseJson = JSON.parse(localforageExportText)
    
    // Dexie
    const dexieExportBlob = await exportDB(db)
    const dexieExportText = await dexieExportBlob.text()
    const dexieDatabaseJson = JSON.parse(dexieExportText)

    // We export both localforage and Dexie databases with Dexie
    // This returns us Blobs, but we want to rearrange those into a single
    // export so we process them into JSON and add them to another JSON structure
    const completeDatabase = {
        "localforage": localforageDatabaseJson,
        "dexie": dexieDatabaseJson
    }

    // We stringify and Blob that for single export, to then be unpackaged on Import
    const completeDatabaseBlob = new Blob([JSON.stringify(completeDatabase)], {type: "application/json"})

    // Client downloads file
    saveAs(completeDatabaseBlob, 'noto_export.noto.json')
}