import React, {useContext, useCallback} from 'react';

import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import {Droppable, Draggable} from "react-beautiful-dnd";
import {VariableSizeList as List} from 'react-window';
import Card from './Card';
import Snap from './Snap';
import AutoSizer from "react-virtualized-auto-sizer";
import ColumnFormContext from "./Forms/Contexts/ColumnForm.context";
import { getDraggableIdString } from '../Helpers/cardReferences';
import AppContext from '../Contexts/App.context';

// Card initial height + margin
const margin = 12;
const cardDefaultHeight = 400;
export const defaultHeight = cardDefaultHeight + margin;

export default function BoardColumn(props) {
    const {tags} = useContext(AppContext)
    const {popColumnFormDialog} = useContext(ColumnFormContext)
    // Return height of Card at index
    const getItemSize = () => {
        return defaultHeight;
        // NOTE: Size checking is, for the moment, removed.
        // - In the future there may be a 'folding' feature, where Card size may return.
        // - Size or 'folds' are expected to be stored with Card data and will therefore
        // allow calculation of size based on the data.
    };

    // Array mappable draggable Card wrapper
    // Basic code example: https://react-window.vercel.app/#/examples/list/fixed-size
    //
    // useCallback here defines the function once and prevents it from rendering each time the component renders
    // this saves flickering of Snaps
    const draggableCard = useCallback(({index, style, data}) => {
        // Assign data from react-window List
        let card = data[index];

        // Check Card object
        if (!card.id) {return false}

        const draggableId = getDraggableIdString(card)

        const cardRender = (provided, snapshot) => {
            if (card.type === 'note') {
                return <Card
                    key={draggableId}
                    card={card}
                    tags={tags}
                    isDragging={snapshot.isDragging}
                    draggableProps={provided.draggableProps}
                    dragHandleProps={provided.dragHandleProps}
                    collection={null}
                />
            }

            if (card.type === 'snap') {
                return <Snap
                    key={draggableId}
                    snap={card}
                    tags={tags}
                    isDragging={snapshot.isDragging}
                    draggableProps={provided.draggableProps}
                    dragHandleProps={provided.dragHandleProps}
                />
            }
        }

        return (
            <Draggable draggableId={draggableId} index={index} key={'draggableCard' + draggableId}>
                {(provided, snapshot) => (
                    <div className={"draggableCard"}
                         style={style}
                         ref={provided.innerRef}
                    >
                        {cardRender(provided, snapshot)}
                    </div>
                )}
            </Draggable>
        );
    }, [tags])

    const renderCardClone = (provided, snapshot, rubric) => {
        const card = props.orderedColumnCards[rubric.source.index]

        if (card.type === 'note') {
            return <Card
                key={card.id}
                card={card}
                tags={tags}
                isDragging={snapshot.isDragging}
                dragHandleProps={provided.dragHandleProps}
                collection={null}
            />
        }

        if (card.type === 'snap') {
            return <Snap
                key={card.id}
                snap={card}
                tags={tags}
                isDragging={snapshot.isDragging}
                dragHandleProps={provided.dragHandleProps}
            />
        }
    }

    return (
        <Draggable
            draggableId={'draggableColumn' + props.column.ID}
            index={props.columnIndex}
            key={'draggableColumn' + props.column.ID}
        >
            {(provided, snapshot) => (
                <Grid
                    className={"BoardColumnWrapper" + (snapshot.isDragging ? ' dragging' : '' )}
                    key={props.index}
                    item
                >
                    <div className={"BoardColumn"} ref={provided.innerRef} {...provided.draggableProps}>
                        <ListItem className={"ColumnTop"}>
                            <ListItemText primary={props.column.title} {...provided.dragHandleProps}/>
                            <IconButton
                                aria-label="Delete"
                                onClick={() => {
                                    props.deleteColumn(props.column)
                                }}
                                size="large">
                                <DeleteIcon/>
                            </IconButton>
                            <IconButton
                                aria-label="Edit"
                                onClick={() => {
                                    popColumnFormDialog(() => props.saveColumn, props.column, props.board)
                                }}
                                size="large">
                                <EditIcon/>
                            </IconButton>
                        </ListItem>

                        <div className={"BoardColumnCardsWrapper"}>
                            <AutoSizer>
                                {({ height, width }) => (
                                    <Droppable
                                        droppableId={'ColumnDroppable' + props.column.ID}
                                        type='CARD'
                                        mode="virtual"
                                        renderClone={(provided, snapshot, rubric) => (
                                            <div {...provided.draggableProps} >
                                                { renderCardClone(provided, snapshot, rubric) }
                                            </div>
                                        )}
                                    >
                                        {(provided) => (
                                            <>
                                                <List
                                                    className={"BoardColumnCards"}
                                                    height={height}
                                                    itemCount={props.orderedColumnCards.length}
                                                    itemData={props.orderedColumnCards}
                                                    itemSize={getItemSize}
                                                    width={width}
                                                    outerRef={provided.innerRef}
                                                    {...provided.droppableProps}
                                                >
                                                    { draggableCard }
                                                </List>
                                            </>
                                        )}
                                    </Droppable>
                                )}
                            </AutoSizer>
                        </div>
                    </div>
                </Grid>
            )}
        </Draggable>
    );
}