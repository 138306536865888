import { Card, CardClass } from "./Card"

export interface Snap extends Card {
    image: Blob
    title?: string
}

export class SnapClass extends CardClass implements Snap {
    image: Blob
    title?: string
    
    constructor(title = null, image) {
        super()
        this.image = image
        this.title = title
        this.type = 'snap'
    }
}