import React, {useState, useEffect, useContext} from 'react'

import BoardOverviewTopBar from './Boards/BoardOverviewTopBar'
import BoardTopBar from './Boards/BoardTopBar'
import Board from '../Board'
import {loadBoards, saveBoards, deleteBoard} from '../../Structures/Board'
import {loadColumns, saveColumns, deleteColumn} from '../../Structures/Column'
import {Boards} from "./Boards/Boards"
import {notify} from "../Notice"
import {searchBoards} from "../../Helpers/cardSearch"
import AppContext from "../../Contexts/App.context.js"
import {BoardFormProvider} from "../Forms/Contexts/BoardForm.context"
import {ColumnFormProvider} from "../Forms/Contexts/ColumnForm.context"
import ConfirmContext from "../../Contexts/Confirm.context"
import NewCardButton from '../Universal/NewCardButton'
import NewSnapButton from '../Universal/NewSnapButton'
import { useSearchTerm } from '../../hooks/useSearchTerm'

function BoardScreen () {
    const {updateScreen, screen, allCards} = useContext(AppContext);
    const {confirm} = useContext(ConfirmContext);

    const [boards, setBoards] = useState([]);
    const [currentBoard, setCurrentBoard] = useState(null);
    const [columns, setColumns] = useState([]);
    const [currentBoardColumns, setCurrentBoardColumns] = useState([]);
    const [cardDrawerOpen, setCardDrawerOpen] = useState(false);
    const [searchTerm, updateSearchTerm, setSearchTerm] = useSearchTerm();
    const [boardsLoading, setBoardsLoading] = useState([]);

    // react-beautiful-dnd Reorder function
    const reorder = (list, startIndex, endIndex) => {
        const removed = list.splice(startIndex, 1);
        list.splice(endIndex, 0, removed[0]);
        return list;
    };

    //<editor-fold desc="Boards Ordering">

    // Update Boards after dragging and dropping
    // (react-beautiful-dnd function)
    // passed to CardOverviewTopBar
    const rearrangeBoard = (result) => {
        // Dropped outside the list OR not moved from original position
        if (!result.destination || result.source.index === result.destination.index) {
            return;
        }

        const rearrangedBoards = reorder(
            boards.slice(),
            result.source.index,
            result.destination.index
        );

        setBoards(rearrangedBoards);
        saveBoards(rearrangedBoards);
    };

    //</editor-fold>

    //<editor-fold desc="Boards">

    // Load Boards to state
    // Relies on columns having been loaded to state
    //
    // @returns {Promise -> Array}
    const getBoards = () => {
        setBoardsLoading(true)
        return loadBoards().then((resultBoards) => {
            // Map over columns and add their IDs to the board they belong to
            // (if not already there)
            resultBoards.forEach(board => {
                // Some Boards should already be saved and have this data arranged,
                // we can skip those ones
                if (!board.columns || board.columns.length === 0) {
                    board.columns = columns
                        // Filter to Columns that belong to Board
                        .filter(column => column.boardID === board.ID)
                        // Return IDs
                        .map(column => { return column.ID; });
                }
            });

            setBoards(resultBoards)
            setBoardsLoading(false)
        });
    };

    // Add/Update supplied Board to storage and state
    //
    // @param board {object} Board object
    //
    // @returns {Promise}
    const saveBoard = (board) => {
        if (!board) return;

        let updatedBoards = boards.slice();

        let updateIndex = -1;
        if (board.ID !== null) {
            // Update Board
            updateIndex = updatedBoards.indexOf(
                // Find Board by ID
                updatedBoards.find((i) => {
                    return i.ID === board.ID;
                })
            );
        }

        // If updateIndex found
        if (updateIndex > -1) {
            // Replace with updated one
            updatedBoards.splice(updateIndex, 1, board);
        } else {
            // Add Board
            updatedBoards.push(board);
        }

        return saveBoards(updatedBoards).then((resultBoards) => {
            setBoards(resultBoards)
        });
    };

    // Delete Board from storage and state
    //
    // @param board {object} Board object
    const handleDeleteBoard = (board) => {
        confirm(() => {
            deleteBoard(board.ID).then((resultBoards) => {
                notify('Board deleted');

                setBoards(resultBoards)
            });

        }, 'Do you want to delete this board?', 'Delete Board');
    };

    // Switch Board display on/off
    //
    // @param index {int} Index of board to update
    //
    // returns {Promise}
    const toggleBoardDisplay = (index) => {
        // Get collections
        let updatedBoards = boards.slice();
        // Toggle display of specified board
        updatedBoards[index].display = !updatedBoards[index].display;

        return saveBoards(updatedBoards).then((resultBoards) => {
            setBoards(resultBoards)
        });
    };

    // Show contents of Board
    //
    // @param id {int} ID of Board to open
    const openBoard = (id) => {
        // Get board by ID
        let board = boards.find(function(b) {
            return b.ID === id;
        });

        setCurrentBoardColumns(getBoardColumns(board));
        setCurrentBoard(board);
        updateScreen('board');
    };

    // Close board (go back to overview screen)
    const closeBoard = () => {
        setCurrentBoard(null);
        setCurrentBoardColumns([]);
        setCardDrawerOpen(false);
        setSearchTerm('');

        // Reset screen
        updateScreen('boards');
    };

    // Return Columns belonging to a Board
    // Board column IDs in
    // Board actual columns out
    //
    // returns {Array}
    const getBoardColumns = (board) => {
        const columnIDs = columns.map(column => column.ID);
        const orderedColumns = [];

        board.columns.forEach(column => {
            const columnIndex = columnIDs.indexOf(column);
            if (columnIndex > -1) {
                orderedColumns.push(columns[columnIndex]);
            }
        });

        return orderedColumns;
    };

    //</editor-fold>

    //<editor-fold desc="Columns">

    // Load Columns to state
    //
    // @returns {Promise -> Array}
    const getColumns = () => {
        return loadColumns().then((resultColumns) => {
            setColumns(resultColumns)
        });
    };

    // Handle Board drag'n'drop functions where a Column or a Card may be dragged
    // Separates Card and Column drag flows
    const rearrangeColumnOrCard = (result) => {
        // Handle Card drag'n'drop
        if (result.type === 'CARD') {
            return rearrangeColumnCard(result);
        }

        // Handle Column drag'n'drop (implicit result.type === 'COLUMN')
        return rearrangeColumn(result);
    };

    // Update a Board's Columns after dragging and dropping
    // (react-beautiful-dnd function)
    const rearrangeColumn = (result) => {
        // Dropped outside the list OR not moved from original position
        if (!result.destination || result.source.index === result.destination.index) {
            return;
        }

        // Get currentBoard
        let updatedCurrentBoard = Object.assign(currentBoard);

        // Get Board Columns
        // Use the columns (currentBoardColumns) in state for reorder so the drag'drop happens in real time
        let boardColumns = [...currentBoardColumns];

        // Reorder Columns
        boardColumns = reorder(
            boardColumns,
            result.source.index,
            result.destination.index
        );

        // Assign re-ordered columns (convert to IDs before for saving)
        updatedCurrentBoard.columns = boardColumns.map(column => column.ID);

        // Update state
        setCurrentBoard(updatedCurrentBoard);
        setCurrentBoardColumns(boardColumns);

        // Save updated Board to storage
        saveBoard(updatedCurrentBoard);
    };

    // Rearrange Column Cards
    //
    // returns {Promise} || false
    const rearrangeColumnCard = (result) => {
        // Dropped outside the list
        // OR not moved from original position
        // OR re-arrange in CardsDrawer
        if (!result.destination ||
            (result.source.droppableId === result.destination.droppableId && // Same Column AND
                result.source.index === result.destination.index) // same Index of that Column
            || (result.source.droppableId === 'cardsList' && result.destination.droppableId === 'cardsList') // Attempt to re-arrange in CardsDrawer
        ) {
            // Don't do anything
            return false;
        }

        // Get column that card was picked up from
        // Get Column ID
        let prevColId = parseInt(result.source.droppableId.replace('ColumnDroppable', ''));
        // Find Column with that ID
        let prevColumn = currentBoardColumns.find(col => col.ID === prevColId);

        // Get column that card was dropped into
        // Get Column ID
        let colId = parseInt(result.destination.droppableId.replace('ColumnDroppable', ''));
        // Find Column with that ID
        let column = currentBoardColumns.find(col => col.ID === colId);

        // CASE: Card pulled from CardsDrawer to Column
        if (result.source.droppableId === 'cardsList') {
            // Add Card to Column if doesn't exist
            if (column.cards.indexOf(result.draggableId) < 0) {
                // Add draggableId to column cards array at dropped index
                column.cards.splice(result.destination.index, 0, result.draggableId);

                // Update Column
                return saveColumn(column);
            }
        }

        // CASE: Card returned to CardsDrawer
        if (result.destination.droppableId === 'cardsList') {
            // Remove from original location
            prevColumn.cards.splice(result.source.index, 1);

            // Update prevColumn
            return saveColumn(prevColumn);
        }

        // Handle error
        if (typeof prevColumn === 'undefined' || typeof column === 'undefined') {
            console.error('Could not find Column.');

            return false;
        }

        // CASE: Re-order Card in same Column
        if (prevColumn.ID === column.ID) {
            // Rearrange Card in Column
            column.cards = reorder(
                column.cards,
                result.source.index,
                result.destination.index
            );

            // Update Column
            return saveColumn(column);
        }

        // CASE: Move Card from one Column to another
        // Remove from original location
        prevColumn.cards.splice(result.source.index, 1);

        // Add Card to Column if doesn't exist
        if (column.cards.indexOf(result.draggableId) < 0) {
            // Add draggableId to column cards array at dropped index
            column.cards.splice(result.destination.index, 0, result.draggableId);
        }

        // Update Columns
        return Promise.all([saveColumn(prevColumn), saveColumn(column)]);
    };

    // Add/Update supplied Column to storage and state
    // Also updates the column's ID reference in its Board
    //
    // @param column {object} Column object
    //
    // @returns {Promise}
    const saveColumn = (column) => {

        let updatedColumns = columns.slice();

        // Column
        let updateIndex = -1;
        if (column.ID !== null) {
            // Update Column
            const columnIDs = updatedColumns.map(col => col.ID);
            updateIndex = columnIDs.indexOf(column.ID);
        }

        // If updateIndex found
        if (updateIndex > -1) {
            // Replace with updated one
            updatedColumns.splice(updateIndex, 1, column);
            setColumns(updatedColumns);
        } else {
            // Add Column
            updatedColumns.push(column);
        }

        // Save columns
        // Update Board's column register
        // .then format used because a column doesn't have ID until it is saved
        return saveColumns(updatedColumns).then((resultColumns) => {
            // If new column add to board column register
            if (updateIndex === -1) {
                let board = Object.assign(currentBoard);

                // Push column.ID to board.columns
                // Last column, ie: just saved one
                board.columns.push(resultColumns[resultColumns.length - 1].ID);

                saveBoard(board);
                setColumns(updatedColumns);
            }

            return resultColumns;
        });
    };

    // Delete Column from storage and state
    //
    // @param board {object} Column object
    const handleDeleteColumn = (column) => {
        confirm(() => {
            deleteColumn(column.ID).then((resultColumns) => {
                notify('Column deleted');

                // Remove column from its Board's register
                let board = Object.assign(currentBoard);
                let removalIndex = board.columns.indexOf(column.ID);

                board.columns.splice(removalIndex, 1);

                const updatedColumns = [...currentBoardColumns];
                updatedColumns.splice(removalIndex, 1);
                setCurrentBoardColumns(updatedColumns);

                // Save with removed column ID
                saveBoard(board).then(() => {
                    // Update state
                    setColumns(resultColumns);
                });
            });

        }, 'Do you want to delete this column?', 'Delete Column');
    };
    //</editor-fold>

    // Open/close the drawer of Cards
    const toggleCardDrawer = () => {
        setCardDrawerOpen(!cardDrawerOpen);
    };

    // Board (current)
    // Show a single ('currently open') board
    const boardView = () => {
        const board = Object.assign(currentBoard);

        return (
            <React.Fragment>
                <BoardTopBar
                    board={board}
                    columns={currentBoardColumns}
                    close={closeBoard}
                />
                <Board
                    board={board}
                    columns={currentBoardColumns}
                    cardDrawerOpen={cardDrawerOpen}
                    toggleCardDrawer={toggleCardDrawer}
                    deleteColumn={handleDeleteColumn}
                    saveColumn={saveColumn}
                    rearrangeColumnOrCard={rearrangeColumnOrCard}
                />
            </React.Fragment>
        );
    };

    // Boards Overview
    // Main screen output
    const boardsOverview = () => {
        if (currentBoard === null || screen === 'boards') {
            return (
                <React.Fragment>
                    <BoardOverviewTopBar
                        boards={boards.slice()}
                        rearrangeBoard={rearrangeBoard}
                        saveBoard={() => saveBoard}
                        deleteBoard={handleDeleteBoard}
                        toggleBoardDisplay={toggleBoardDisplay}

                        searchCards={updateSearchTerm}
                    />
                    <Boards
                        boards={searchBoards(boards.slice(), searchTerm)}
                        open={openBoard}
                        saveBoard={() => saveBoard}
                        getBoardColumns={getBoardColumns}
                        cards={allCards}
                        searchTerm={searchTerm}
                        isLoading={boardsLoading}
                    />
                </React.Fragment>
            );
        }
    };

    useEffect(() => {
        getColumns().then(() => {
            // Get Boards after Columns because they require Columns
            getBoards();
        });
    }, []);

    // When columns are updated if we have a currentBoard we update columns in state
    // Eg: will happen on rearrange, new column, delete column
    useEffect(() => {
        if (currentBoard) {
            setCurrentBoardColumns(getBoardColumns(currentBoard))
        }
    }, [columns]);

    // What gets rendered
    let output = boardsOverview();

    // Board
    if (currentBoard !== null && screen === 'board') {
        // Prepare to show board contents, ensure board is made immutable
        output = boardView();
    }

    return (
        <div className="Screen">
            <BoardFormProvider>
            <ColumnFormProvider>
                {output}

                <NewSnapButton cardDrawerOpen={cardDrawerOpen}/>
                <NewCardButton cardDrawerOpen={cardDrawerOpen}/>
            </ColumnFormProvider>
            </BoardFormProvider>
        </div>
    );
}

export default BoardScreen;