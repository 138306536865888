import React, { useContext } from 'react'
import {List} from '@mui/material'
import AddPromptCard from '../../AddPromptCard'
import JourneyFormContext from "../../Forms/Contexts/JourneyForm.context"
import { JourneyListItem } from './JourneyListItem'
import JourneyContext from '../../../Contexts/Journey.context'
import {collectCards} from '../../../Structures/Collection'
import AppContext from '../../../Contexts/App.context'
import {Route} from '@mui/icons-material'
import { EmptyStatePrompt } from '../../Universal/EmptyStatePrompt'
import { EmptyMessage } from '../../../Helpers/tools'

export function Journeys ({open, journeys, cards, searchTerm, isLoading}) {
    const {popJourneyFormDialog} = useContext(JourneyFormContext)
    const {handleCreateJourney} = useContext(JourneyContext)
    const {tags} = useContext(AppContext)

    const newJourneyPrompt = () => {popJourneyFormDialog(() => handleCreateJourney, null, tags)}

    const noJourneys = journeys.length === 0
    const emptyStatePrompt = noJourneys && !searchTerm
    const searchEmptyState = noJourneys && searchTerm

    if (isLoading) return null
    
    if (emptyStatePrompt) return <EmptyStatePrompt
        icon={<Route style={{transform: "rotate(90deg) scaleX(-1)"}}/>}
        message="Let's start a Journey!"
        onClick={newJourneyPrompt}
    />

    if (searchEmptyState) return <EmptyMessage />

    return (
        <List className='JourneysList'>
            {journeys.filter(journey => journey.display)
            .map((journey, index) => {
                const journeyCards = collectCards(cards, journey)
                
                let journeyMerit = 0
                
                const journeyDates = journeyCards.map(card => {
                    journeyMerit += card.merit
                    return new Date(card.created_at)
                })
                journeyDates.sort((a, b) => a.getTime() - b.getTime())

                return (
                    <JourneyListItem
                        key={index}
                        name={journey.title}
                        onClick={() => {open(journey.id)}}
                        dates={journeyDates}
                        merit={journeyMerit}
                    />
                )
            })}
            <AddPromptCard function={newJourneyPrompt}/>
        </List>
    )
}