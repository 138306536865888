import React, {useState, useEffect} from "react";

export const useEasterEgg = () => {
    const [easterEgg, setEasterEgg] = useState(getEasterEgg())
    
    useEffect(() => {
        const ticker = setInterval(() => {
            setEasterEgg(getEasterEgg())
        }, 60000) // 1 min

        return () => clearInterval(ticker)
    }, [])
    
    return easterEgg;
  }

const getEasterEgg = (): string | undefined => {
    // Is today an Easter Egg day?
    const now = new Date()
    const day = now.getDate()
    const month = now.getMonth() + 1

    return easterEggCalendar[month] ? easterEggCalendar[month][day] : undefined
}

// Month, Day, Easter Egg name
const easterEggCalendar = {
    8: {
        21: "noto-birthday"
    },
    12: {
        24: "xmas",
        25: "xmas",
        26: "xmas"
    }
}

export const notoBirthday = <>
    <h1>✨ 🎉 happy birthday noto! 🎉 ✨</h1>
    <p>2018-08-21</p>
    <hr></hr>
</>