import {db} from '../db'

export async function createSnap(snap): Promise<boolean> {
  try {
    // Delete type metadata
    // NOTE: This should not be stored as it is for reference only by the frontend
    // by summoning this information we know the type, that's where we will assign this metadata
    const strippedSnap = structuredClone(snap)
    delete strippedSnap.type

    await db.snaps.add(strippedSnap)

    return true
  } catch (error) {
    return false
  }
}

export async function updateSnap(snap): Promise<boolean> {
  try {
    // Delete type metadata
    // NOTE: This should not be stored as it is for reference only by the frontend
    // by summoning this information we know the type, that's where we assign this metadata
    const strippedSnap = structuredClone(snap)
    delete strippedSnap.type
    strippedSnap.updated_at = new Date()

    // @ts-ignore - https://github.com/dexie/Dexie.js/issues/2001
    await db.snaps.update(strippedSnap.id, strippedSnap)

    return true
  } catch (error) {
    return false
  }
}

export async function deleteSnap(id): Promise<boolean> {
  try {
    await db.snaps.delete(id)

    return true
  } catch (error) {
    return false
  }
}