import React from "react"
import SnapsRelease from "../Components/Content/SnapsRelease";

export function urlToDialogue() {
    const urlPath = location.pathname
    history.replaceState(null, '', '/')

    switch (urlPath) {
        case '/snaps':
            return <SnapsRelease/>          
        default:
            return null   
      }
}