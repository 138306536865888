import React, {useContext} from 'react'
import { Fab, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import NoteFormContext from '../Forms/Contexts/NoteForm.context'
import AppContext from '../../Contexts/App.context'
import {useBackupPrompt} from "../../hooks/useBackupPrompt"

const NewCardButton = ({cardDrawerOpen = false, collection = null}) => {
    const {popNoteFormDialog} = useContext(NoteFormContext)
    const {loadCards, attemptPersistentStorageGrant} = useContext(AppContext)
    const backupPrompt = useBackupPrompt()

    const onSuccess = () => {
        loadCards()
        attemptPersistentStorageGrant()
        backupPrompt()
    }

    return (
        <Tooltip
            title="New Card"
            placement="left"
            enterDelay={1000}
            leaveDelay={200}
            slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 20],
                      },
                    },
                  ],
                },
              }}
        >
            <div className={"NewCardButton" + (cardDrawerOpen ? ' cardDrawerOpen' : '')}>
                <Fab
                    variant="circular"
                    color="primary"
                    aria-label="New Card"
                    className="NewCardButtonFab"
                    onClick={() => {popNoteFormDialog(() => onSuccess, null, collection)}}
                >
                    <AddIcon />
                </Fab>
                <div className='DisplayCards'>
                    <div className='DisplayCard One'/>
                    <div className='DisplayCard Two'/>
                    <div className='DisplayCard Three'/>
                    <div className='DisplayCard Four'/>
                    <div className='DisplayCard Five'/>
                </div>
            </div>
        </Tooltip>
    )
}

export default NewCardButton