import React, {useContext} from 'react'
import Grid from '@mui/material/Grid'
import AddPromptCard from '../../AddPromptCard'
import {Deck} from "../../Deck"
import BoardFormContext from "../../Forms/Contexts/BoardForm.context"
import {Dashboard} from '@mui/icons-material'
import { EmptyStatePrompt } from '../../Universal/EmptyStatePrompt'
import { EmptyMessage } from '../../../Helpers/tools'
import { getDraggableIdString } from '../../../Helpers/cardReferences'

export function Boards ({boards, saveBoard, getBoardColumns, cards, open, searchTerm, isLoading}) {
    const {popBoardFormDialog} = useContext(BoardFormContext)

    const newBoardPrompt = () => { popBoardFormDialog(saveBoard) }

    const noBoards = boards.length === 0
    const emptyStatePrompt = noBoards && !searchTerm
    const searchEmptyState = noBoards && searchTerm

    if (isLoading) return null
    
    if (emptyStatePrompt) return <EmptyStatePrompt
        icon={<Dashboard />}
        message="Let's make a Board!"
        onClick={newBoardPrompt}
    />

    if (searchEmptyState) {
        return <EmptyMessage />
    }

    return (
        <Grid container
              className="cardBoards"
              direction="row"
              justifyContent="space-evenly"
              alignItems="flex-start"
        >
            {boards.filter(function(board) {
                // Filter out hidden boards
                return board.display
            })
            .map(function(board, index) {
                const boardCardIds = [].concat(getBoardColumns(board).map(x => x.cards)).flat()
                const boardCards = cards.filter((card) => {
                    // Legacy handling of old ID only data - From when we stored an array of Card.ID only
                    if (card.type === 'note' && boardCardIds.includes(card.id)) return true
                    // Check for card reference
                    return boardCardIds.includes(getDraggableIdString(card))
                })

                return (
                    <Grid key={index} item>
                        <Deck
                            cards={boardCards}
                            onClick={() => {open(board.ID)}}
                            label={board.title}
                        />
                    </Grid>
                )
            })}
            <AddPromptCard function={newBoardPrompt}/>
        </Grid>
    )
}