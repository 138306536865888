import React, {Component} from 'react'

import Dialog from '@mui/material/Dialog'
import Tilt from 'react-parallax-tilt'
import AppContext from "../Contexts/App.context.js"
import Card from "./Card"
import Snap from './Snap'

// This specific dialog is just listening for a context change to pop
export default class CardFocusDialog extends Component {
    // Register AppContext (accessible as this.context)
    static contextType = AppContext;

    // Close dialog box
    handleClose = () => {
        this.context.updateFocusCard(null);
        this.context.updateLinkedCard(null);
    };

    renderFocusCard = (card) => {
        if (!!card) {
            if (card.type === 'note') {
                return <Card
                    key={card.id}
                    card={card}
                    tags={this.context.tags}
                    inDialog={true}
                />
            }
            if (card.type === 'snap') {
                return <Snap key={card.id} snap={card} inDialog={true}/>
            }
        }
    }

    render() {
        const dualViewTrue = this.context.focusCard && (this.context.linkedCard.card && !this.context.linkedCard.cleared) ? 'DualView' : null;
        const paperClasses = [
            'CardDialog',
            dualViewTrue
        ];

        return (
            <Dialog
                className='CardDialogRoot'
                open={!!this.context.focusCard}
                onClose={this.handleClose}
                classes={{
                    'paper': paperClasses.join(' ')
                }}
                disablePortal
            >
                <Tilt
                    className="Tilt FocusCard"
                    tiltMaxAngleX={10}
                    tiltMaxAngleY={10}
                    scale={1.05}
                    perspective={1000}
                    transitionSpeed={1000}
                >
                    { this.renderFocusCard(this.context.focusCard) }
                </Tilt>
                <div className={'LinkedCardAnimationWrapper'}>
                    {this.context.linkedCard.card &&
                        <Tilt
                            className="Tilt LinkedCard"
                            tiltMaxAngleX={10}
                            tiltMaxAngleY={10}
                            scale={1.05}
                            perspective={1000}
                            transitionSpeed={1000}
                        >
                            { this.renderFocusCard(this.context.linkedCard.card) }
                        </Tilt>
                    }
                </div>
            </Dialog>
        );
    }
}