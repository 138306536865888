import React from 'react'

import MuiCardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ThumbUpIcon from '@mui/icons-material/ThumbUpRounded'
import ThumbDownIcon from '@mui/icons-material/ThumbDownRounded'
import EditIcon from '@mui/icons-material/Edit'
import ArchiveIcon from '@mui/icons-material/Archive'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import DeleteIcon from '@mui/icons-material/DeleteRounded'
import FlipIcon from '@mui/icons-material/Flip'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'

import {Tags} from "../Cards"
import {Tag} from '../../Structures/Tag'
import {expandDuration} from '../../Helpers/constants'
import {dateFormat} from "../../Helpers/dateHelper"

type CardActionsProps = {
    expanded: boolean
    tags: Tag[]
    expandToggle: (open?: any) => void
    date: Date
    retired: boolean
    deleteAction: () => void
    editAction: () => void
    retireAction: () => void
    privateAction: () => void
    meritAction: (n: number) => void
}

export default function CardActions({expanded, tags, expandToggle, date, retired, deleteAction, editAction, retireAction, privateAction, meritAction}: CardActionsProps) {
    return <div className="CardActions">
        <MuiCardActions>
            <Tags cardExpanded={expanded} tags={tags}/>

            <Tooltip title={expanded ? 'Less' : 'More'} placement="top" enterDelay={500} leaveDelay={200}>
                <IconButton
                    className={'icon-button expand-icon-' + expanded}
                    onClick={expandToggle}
                    aria-expanded={expanded}
                    aria-label="Show more"
                    size='small'
                >
                    <ExpandMoreIcon />
                </IconButton>
            </Tooltip>
        </MuiCardActions>

        <Collapse in={expanded} timeout={expandDuration} unmountOnExit>
            <div className="Date">{dateFormat(date)}</div>
            <MuiCardActions>
                <Grid container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                >
                    <Grid item xs={2}>
                        <Tooltip
                            title="Delete"
                            placement="top"
                            enterDelay={500}
                            leaveDelay={200}
                        >
                            <span>
                            <Button
                                onClick={deleteAction}
                                fullWidth
                                className={'cardActionButton'}
                                color='inherit'
                            >
                                <DeleteIcon />
                            </Button>
                            </span>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={2}>
                        <Tooltip
                            title="Edit"
                            placement="top"
                            enterDelay={500}
                            leaveDelay={200}
                        >
                            <span>
                            <Button
                                onClick={editAction}
                                fullWidth
                                className={'cardActionButton'}
                                color='inherit'
                            >
                                <EditIcon />
                            </Button>
                            </span>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={2}>
                        <Tooltip
                            title={retired ? "Un-retire" : "Retire"}
                            placement="top"
                            enterDelay={500}
                            leaveDelay={200}
                        >
                            <span>
                            <Button
                                onClick={retireAction}
                                fullWidth
                                className={'cardActionButton'}
                                color='inherit'
                            >
                                { retired ? <UnarchiveIcon /> : <ArchiveIcon /> }
                            </Button>
                            </span>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={2}>
                        <Tooltip
                            title={'Flip'}
                            placement="top"
                            enterDelay={500}
                            leaveDelay={200}
                        >
                            <span>
                            <Button
                                onClick={privateAction}
                                fullWidth
                                className={'cardActionButton'}
                                color='inherit'
                            >
                                <FlipIcon/>
                            </Button>
                            </span>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={2} className={'meritButton'}>
                        <Tooltip title="Add Merit" placement="top" enterDelay={500} leaveDelay={200}>
                            <span>
                                <Button
                                    onClick={() => meritAction(1)}
                                    fullWidth
                                    className={'cardActionButton'}
                                    color='inherit'
                                >
                                    <ThumbUpIcon />
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title="Subtract Merit" placement="bottom" enterDelay={500} leaveDelay={200}>
                            <span className={'subtractMerit'}>
                                <IconButton
                                    onClick={() => meritAction(-1)}
                                    className={'cardActionButton'}
                                    size="large">
                                    <ThumbDownIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
            </MuiCardActions>
        </Collapse>
    </div>
}