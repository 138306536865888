import { createContext, useState } from 'react'
import { Snap } from '../../../database/models/Snap'

const SnapFormContext = createContext({
    open: false,
    snap: null,
    cardBack: null,
    setCardBack: (string) => {},
    onSuccess: () => {},
    closeDialogue: () => {},
    unsavedChanges: false,
    setUnsavedChanges: (bool) => {},
    popSnapFormDialog: (onSuccessFn, snap?: Snap) => {}
})

const SnapFormProvider = ({ children }) => {
    const [open, setOpen] = useState(false)
    const [snap, setSnap] = useState(null)
    const [cardBack, setCardBack] = useState(null)
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [onSuccess, setOnSuccess] = useState(undefined)

    const popSnapFormDialog = (onSuccessFn, snap?: Snap) => {
        if (!(onSuccessFn instanceof Function)) {
            throw new Error('Parameter "onSuccessFn" is not a function')
        }
        
        
        setSnap(snap)
        setCardBack(snap?.back || 'default')
        setOnSuccess(onSuccessFn)
        setOpen(true)
    }

    const closeDialogue = () => {
        setOpen(false)
        setSnap(null)
        setUnsavedChanges(false)
        setCardBack(null)
        setOnSuccess(undefined)
    }

    return (
        <SnapFormContext.Provider value={{
            open,
            snap,
            cardBack,
            setCardBack,
            onSuccess,
            unsavedChanges,
            closeDialogue,
            setUnsavedChanges,
            popSnapFormDialog
        }}>
            {children}
        </SnapFormContext.Provider>
    )
}

export {SnapFormProvider}
export default SnapFormContext