import React, {useContext} from 'react'

import Grid from '@mui/material/Grid'
import Fab from '@mui/material/Fab'
import Tooltip from '@mui/material/Tooltip'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import AddPromptCard from './AddPromptCard'
import CardsDrawer from './CardsDrawer'
import BoardColumn from './BoardColumn'
import {DragDropContext, Droppable} from "react-beautiful-dnd"
import {searchCards} from "../Helpers/cardSearch"
import ColumnFormContext from "./Forms/Contexts/ColumnForm.context"
import { useSearchTerm } from '../hooks/useSearchTerm'
import {Dashboard} from '@mui/icons-material'
import { EmptyStatePrompt } from './Universal/EmptyStatePrompt'
import AppContext from "../Contexts/App.context"
import { getDraggableIdString, cardInReferenceArray } from '../Helpers/cardReferences'


export default function Board({board, deleteColumn, saveColumn, cardDrawerOpen, toggleCardDrawer, columns, rearrangeColumnOrCard}) {
    const {allCards} = useContext(AppContext);

    const {popColumnFormDialog} = useContext(ColumnFormContext)
    const [searchTerm, updateSearchTerm] = useSearchTerm()

    const newColumnPrompt = () => {popColumnFormDialog(() => saveColumn, null, board)}

    const emptyStatePrompt = columns.length === 0

    const renderColumn = (column, index) => {
        if (!column) {
            console.error('Error with column in renderColumns, column rendering cancelled. column:', column)
            return
        }

        let columnCards = allCards.filter((card) => {
            // Filter to cards IN columns           
            return cardInReferenceArray(card, column.cards)
        })

        // Order the cards
        let orderedColumnCards = column.cards.slice(0)
        columnCards.map((card) => {
            // Take this data
            // orderedColumnCards - [1,2,3,4,'snap-20']
            const insertIndex = orderedColumnCards.findIndex((orderedColumnCardReference) => {
                // 'type-id' string eg: snap-20, note-2
                let cardReference = orderedColumnCardReference
                // Archaic Card.ID handling
                if (Number.isInteger(orderedColumnCardReference)) {
                    // Rewrite with new structure 'note-xx', xx = id - this only applies to notes as they are old
                    cardReference = 'note-' + orderedColumnCardReference
                }

                // Search by new card reference
                return cardReference === getDraggableIdString(card)
            })

            // Replace Card ID with the Card
            if (insertIndex > -1) {
                orderedColumnCards.splice(insertIndex, 1, card)
            }
        })

        // Strip remaining IDs (could be left over from deleted cards),
        // they should all be Card objects now
        orderedColumnCards = orderedColumnCards.filter((i) => {return !Number.isInteger(i) && typeof i !== 'string'})

        return <BoardColumn
            key={index}
            columnIndex={index}
            board={board}
            column={column}
            deleteColumn={deleteColumn}
            saveColumn={saveColumn}
            orderedColumnCards={orderedColumnCards}
        />
    }

    const cardDrawerButton = () => {
        return (
            <Tooltip title="Card Drawer" placement="right" enterDelay={500} leaveDelay={200}>
                <Fab
                    variant="circular"
                    color="default"
                    aria-label="Card Drawer"
                    className={"ToggleCardDrawerButton" + (cardDrawerOpen ? ' open' : '')}
                    onClick={toggleCardDrawer}
                >
                    <ExpandLessIcon className={"ToggleCardDrawerButtonIcon"}/>
                </Fab>
            </Tooltip>
        )
    }

    const allColumnCardReferences = () => {
        let cardReferences = []
        columns.forEach(col => cardReferences.push(...col.cards))
        return cardReferences
    }

    // Remove cards that are in a Column
    const drawerCards = () => {
        const columnCardReferences = allColumnCardReferences()

        // Take this data - [1,2,3,4,'snap-20']
        return allCards.filter((card) => {
            // Filter to cards NOT in columns
            return !cardInReferenceArray(card, columnCardReferences)
        })
    }

    const renderColumns = () => {
        if (emptyStatePrompt) return <EmptyStatePrompt
            icon={<Dashboard />}
            message="Let's make your first Column!"
            onClick={newColumnPrompt}
        />

        return <Droppable droppableId='board' direction='horizontal' type='COLUMN'>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    <Grid container
                        className={"Board" + (cardDrawerOpen ? ' cardDrawerOpen' : '')}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        wrap="nowrap"
                    >
                        {columns.map(renderColumn)}

                        {provided.placeholder}

                        <AddPromptCard
                            function={newColumnPrompt}
                            className='BoardColumnWrapper'
                        />
                    </Grid>
                </div>
            )}
        </Droppable>
    }

    // Set CSS variable to be used to calculate Board width to ensure Column dragging works
    document.documentElement.style.setProperty("--num-columns", columns.length)

    return (
        <React.Fragment>
            <DragDropContext onDragEnd={rearrangeColumnOrCard}>
                {renderColumns()}

                {cardDrawerButton()}

                <CardsDrawer
                    cards={searchCards(drawerCards(), searchTerm)}
                    open={cardDrawerOpen}
                    searchCards={updateSearchTerm}
                />
            </DragDropContext>
        </React.Fragment>
    )
}