import localforage from "localforage";
import {getRandomInt} from "../Helpers/tools";

// <editor-fold desc="Card">
// This file represents all Card data and structure functionality

// <editor-fold desc="Card Helpers">

// Restore Card
//
// Restore an object that was previously an instance of Card class,
// to an instance of Card class (lost during local storage)
//
// @param card {object} previously a Card class object,
// stripped by saving to local storage
function restoreCard(card) {
    // Place object values onto new Card(), overwriting them.
    const cardObject = Object.assign(new Card(), card)

    // Assign type metadata
    // NOTE: This should not be stored as it is for reference only by the frontend
    // by summoning this information we know the type, that's where we will assign this metadata
    cardObject.type = 'note'

    // Legacy .ID handling
    // Card was originally created with id stored as .ID - I'm switching to a more
    // standard usage, .id - we'll run both to support new and legacy for now.
    // We set this here.
    cardObject.id = cardObject.ID

    return cardObject
}

// Get cards data from storage
//
// @returns {Promise -> Array} Promise to return array of Card Objects
export function getCards() {
    return localforage.getItem('cards').then((cards) => {
        // If no cards, new array
        cards = cards ? cards : [];

        // Restore Card class instance to each card
        cards = cards.map(card => restoreCard(card));

        return cards;

    }).catch(function(err) {
        console.log(err);
        return [];
    });
}

// Strip card down to plain Object for storage
//
// @param card {Object} Card Class Object
//
// @returns {Object} Object stripped of Class features/functions
function stripCard(card) {
    const strippedCard = JSON.parse(JSON.stringify(card))
    // Remove reference only metadata
    // NOTE: This should not be stored as it is for reference only by the frontend
    // by summoning this information we know the type, that's where we will assign this metadata
    delete strippedCard.type

    return strippedCard
}

// Set cards data to storage
//
// @param cards {Array} Array of Card Objects
//
// @returns {Promise} Promise to set cards to storage
function setCards(cards) {
    // Strip cards for storage
    cards = cards.map(card => stripCard(card));

    return localforage.setItem('cards', cards).catch(function(err) {
        console.log(err);
    });
}

// Handle auto incrementing IDs
//
// @return {Promise -> Int} Evaluates to incremented ID
function generateID() {
    return localforage.getItem('cardIdIncrementer').then(function (ID) {
        // Increment cardIdIncrementer and return
        return localforage.setItem('cardIdIncrementer', ID + 1)
            .then((result) => {return result});

    }).catch(function(error) {
        console.log(error);
    });
}
//</editor-fold>

// The Card class
export class Card {
    constructor() {
        this.ID = null;
        this.content = null;
        this.tags = [];
        this.belongs_to_collections = [];
        this.merit = 0;
        this.private = false;
        this.retired_at = null;
        this.created_at = new Date();
        this.updated_at = null;
        this.deleted_at = null;
        this.back = null; // Which card back design is applied
        this.wear = 0; // Track how much wear a Card has
        this.wear_variant = getRandomInt(4); // Weariant..? - Used for maintaining which variant of wear is applied
        this.is_template = false
        // Add type to legacy Card data so we can use a 'type' based
        // logic approach in functions
        // NOTE: This should not be stored as it is for reference only by the frontend
        // by summoning this information we know the type, that's where we will assign this metadata
        this.type = 'note'
    }

    // Save/update card data to storage
    //
    // @returns {Promise} Promise to save the card
    // (Promise to getCards(), then setCards())
    save = () => {
        // Get existing cards
        return getCards().then(cards => {
            // Update
            // If Card already has ID
            if (this.ID) {
                // Existing card index
                let existingCard = cards.indexOf(
                    // Find card by ID
                    cards.find((card) => {
                        return card.ID === this.ID;
                    })
                );

                // Set updated_at
                this.updated_at = new Date();

                // If card index found
                if (existingCard > -1) {
                    // Replace card with updated one
                    cards.splice(existingCard, 1, this);

                    // Set cards data to storage
                    return setCards(cards);
                }
            }

            // Create
            // Set card ID
            return this.setId().then(() => {
                // Add self (this Card) to array
                cards.push(this);

                // Set cards data to storage
                return setCards(cards);
            });
        });
    };

    // Set the card ID to a generated unique ID
    // To be used before saving/updating a Card
    //
    // @return {Promise} Evaluates to ID
    setId() {
        return generateID().then((ID) => {
            this.ID = ID;
        });
    }

    // Change card merit
    // @param amount {int} Amount to increase/decrease Card's merit (can be +/-)
    //
    // @returns {int} Adjusted Card merit
    addMerit = (amount) => {
        return this.merit = this.merit + Math.round(amount);
    };

    // Toggle card privacy
    togglePrivate() {
        return this.private = !this.private;
    }

    // Toggle retired state of card
    // Set Card retired_at to now or null
    toggleRetired = () => {
        this.retired_at = this.retired_at ? null : new Date();
    };

    // Delete card
    //
    // @returns {Promise} Promise to delete the card
    delete = () => {
        // Get existing cards
        return getCards().then(cards => {
            // Target card index
            let targetCard = cards.indexOf(
                // Find card by ID
                cards.find((card) => {
                    return card.ID === this.ID;
                })
            );

            // If card index found
            if (targetCard > -1) {
                // Remove card
                cards.splice(targetCard, 1);

                // Set cards data to storage
                return setCards(cards);
            }

            throw new Error('Couldn\'t find Card by ID to delete.');
        });
    };
}
//</editor-fold>

//</editor-fold>