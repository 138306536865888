import React, {useContext} from 'react'
import Snap from '../../images/content/snap.png'
import Bloom from '../../images/card_back/bloom.png'
import SnapJourney from '../../images/content/snap_journey.png'
import NewSnapButton from '../Universal/NewSnapButton'
import PhilosophyBoard from '../../images/content/snaps_philosophy_board.png'
import IdeasBoard from '../../images/content/snaps_inspo_board.png'
import InfoDialogContext from "../../Contexts/InfoDialog.context";

export default function SnapsRelease() {
    const {handleClose} = useContext(InfoDialogContext)

    return (
        <div className='snaps-release-content'>
            <div className="introLogo"/>
            
            <div className='showcase spotlight'>
                <div className='image'>
                    <img alt='Polaroid picture style Noto Snap' src={Snap} className='noto-snap'/>
                </div>
                <div className='words'>
                    <div className='punchyTitle'>
                        <strong>introducing</strong>
                        <h1 className='splash'>Snaps</h1>
                    </div>
                    <p>Bring new life to your noto experience with <strong>Snaps</strong>. Pictures that work alongside the entire suite of features.</p>
                </div>
            </div>

            <div className='showcase'>
                <div className='words left'>
                    <div className='punchyTitle'>
                        <strong>new card back</strong>
                        <h1>Bloom</h1>
                    </div>
                </div>
                <div className='image'>
                    <img alt='The new Noto Card Back - Bloom' src={Bloom} className='cardback'/>
                </div>
            </div>

            <div className='content'>
                <div className='punchyTitle'>
                    <strong>make a</strong>
                    <h1>Snap</h1>
                </div>
                <NewSnapButton bonusAction={() => handleClose()}/>
            </div>

            <div className='content'>
                <div className='punchyTitle'>
                    <strong>unfolding</strong>
                    <h1>Your Story</h1>
                </div>
                <p>What does your story look like as you <strong>Snap</strong> along the way.</p>

                <div className='screenshot'>
                    <img alt="A timeline of Noto Snaps showing a traveller's journey" src={SnapJourney} className='snap-journey'/>
                </div>
            </div>
            
            <div className='content'>
                <div className='punchyTitle'>
                    <strong>explore</strong>
                    <h1>Your Ideas</h1>
                </div>
                <p>Throw it all down and arrange as you like.</p>

                <div className='screenshot-duo'>
                    <img alt="Noto Board showing study of Philosophy" src={PhilosophyBoard} className='snap-philosophy'/>
                    <img alt="Noto Board showing some ideas" src={IdeasBoard} className='snap-ideas'/>
                </div>
            </div>

            <div className='content spotlight'>
                <div className='punchyTitle'>
                    <strong>ready and waiting for you</strong>
                    <h1>Snap it up</h1>
                </div>
                <NewSnapButton bonusAction={() => handleClose()}/>
            </div>
        </div>
    )
}