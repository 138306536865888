import { createContext, useState } from 'react';
import {Card as CardClass} from "../../../Structures/Card";
import {CollectionClass} from "../../../Structures/Collection";

const NoteFormContext = createContext();

const NoteFormProvider = ({ children }) => {
    const [open, setOpen] = useState(false)
    const [card, setCard] = useState(null)
    const [collection, setCollection] = useState(null)
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [cardBack, setCardBack] = useState(null)
    const [onSuccess, setOnSuccess] = useState(undefined)

    const popNoteFormDialog = (onSuccessFn, card = null, collection = null) => {
        if (!(onSuccessFn instanceof Function)) {
            throw new Error('Parameter "onSuccessFn" is not a function');
        }

        if (card !== null && !(card instanceof CardClass)) {
            throw new Error('Parameter "card" is not an instance of Card Class OR null');
        }

        if (collection !== null && !(collection instanceof CollectionClass)) {
            throw new Error('Parameter "collection" is not an instance of Collection Class OR null');
        }

        setCard(card)
        setCardBack(card?.back || 'default')
        setCollection(collection)
        setOnSuccess(onSuccessFn)
        setOpen(true)
    };

    const closeDialogue = () => {
        setOpen(false);
        setCard(null);
        setCollection(null);
        setUnsavedChanges(false);
        setCardBack(null);
        setOnSuccess(undefined);
    };

    return (
        <NoteFormContext.Provider value={{
            open,
            card,
            onSuccess,
            collection,
            unsavedChanges,
            cardBack,
            closeDialogue,
            setUnsavedChanges,
            setCardBack,
            popNoteFormDialog
        }}>
            {children}
        </NoteFormContext.Provider>
    );
};

export {NoteFormProvider}
export default NoteFormContext