import React, {useState, useContext, useCallback, useMemo, memo} from 'react';
import {debounce} from "lodash";

// Material Design Components
import Tooltip from '@mui/material/Tooltip';
import MDCCard from '@mui/material/Card';

// Custom Components
import {tagDataForCard} from "../Structures/Tag";
import AppContext from "../Contexts/App.context.js";
import {cardValueLabel} from "./CardValue";
import {cardWearClass, cardWearVariantClass} from "../Helpers/cardWear";
import ConfigContext from "../Contexts/Config.context";
import CardActions from './CardFeatures/Actions';
import RetiredBadge from './CardFeatures/RetiredBadge';
import MeritBadge from './CardFeatures/MeritBadge';
import Flipper from './CardFeatures/Flipper';
import { SnapClass } from '../database/models/Snap';
import SnapFormContext from './Forms/Contexts/SnapForm.context';
import SnapContext from '../Contexts/Snap.context';

type SnapProps = {
    snap: SnapClass
    isDragging?: boolean
    inDialog?: boolean
    draggableProps?: any
    dragHandleProps?: any
}

function Snap({snap, isDragging, inDialog, draggableProps, dragHandleProps}: SnapProps) {
    const {tags, updateFocusCard, updateLinkedCard, linkedCard, focusCard} = useContext(AppContext)

    const {popSnapFormDialog} = useContext(SnapFormContext)
    const {handleUpdateSnap, handleDeleteSnap, handleToggleSnapRetired, handleToggleSnapPrivate, handleUpdateSnapMerit} = useContext(SnapContext)

    // Register config context
    const {config} = useContext(ConfigContext);

    const [expanded, setExpanded] = useState(false);
    const [merit, setMerit] = useState(snap.merit);

    const onSuccess = (snap) => {
        return handleUpdateSnap(snap)
    }

    // Handle show/hide of additional Card options
    //
    // @param open {bool} Set to open? (defaults to null then and uses expanded state)
    const expandToggle = (open = null) => {
        // Handle case that open is the event from onClick
        if (open !== null && typeof(open) !== "boolean") {
            open = null;
        }

        setExpanded(null !== open ? open : !expanded);
    }

    const setFocus = (event = null) => {
        // Snap setFocus has paired back rules because it only has an image
        // - it does not support fancy things like CardLinks

        // Rules for allowing focus
        if (event && event.target) {
            // - Disallow focus/clear linked Card if this is the LinkedCard
            // Todo: This also disallows closing a self linked Card
            if (linkedCard.card) {
                if (linkedCard.type === 'snap') {
                    if (linkedCard.card.id === snap.id) return
                }
            }
            // - No focus if click inside actions
            if (event.target.closest('.CardActions')) return;
            // - No focus if selecting
            if (!!window.getSelection().toString()) return;
        }

        // Check context and catch Board drag'n'drop events and prevent them from activating a flip
        if (focusCard || isDragging) return;

        updateFocusCard(snap);
    }

    const togglePrivate = () => {
        // Catch Board drag'n'drop events and prevent them from activating a flip
        if (isDragging) return;

        handleToggleSnapPrivate(snap)
    }

    // Handle merit button on Card
    const incrementMerit = (n: number) => {
        // Do nothing if goes below 0
        if (merit + n < 0) return

        setMerit(merit + n)
        debouncedUpdateMerit(merit + n)
    }

    // How does this work? See this article: https://www.developerway.com/posts/debouncing-in-react
    const updateMerit = useCallback((snap) => handleUpdateSnapMerit(snap), [snap])

    const debouncedUpdateMerit = useMemo(() => {
        return debounce((newMerit) => {
            snap.merit = newMerit
            updateMerit(snap)
        }, 500)
    }, [updateMerit])

    const date = new Date(snap.created_at)
    const retired = null !== snap.retired_at
    const snapTags = tagDataForCard(tags, snap.tags)
    const classes = [
        'Card',
        'Snap',
        cardValueLabel(merit),
        isDragging ? 'dragging' : null,
        snap.private ? 'private' : null,
        retired ? 'retired' : null,
        expanded ? 'expanded' : null,
        snap.back ? 'cardBack_' + snap.back : null,
        config.cardWear ? cardWearClass(snap.wear) : null,
        config.cardWear ? cardWearVariantClass(snap.wear_variant) : null,
        // If this Snap is focused, placeholder is output
        (!inDialog && focusCard && focusCard.type === 'snap' && focusCard.id === snap.id) ? 'hidden' : null
    ];

    // Don't fail if image is messed up (eg: set to null somehow - bad data)
    const src = useMemo(() => snap.image ? URL.createObjectURL(snap.image) : null, [snap.image])

    return (
        <Tooltip
                title={!inDialog ? "Click to Focus" : ''}
                placement="top"
                enterDelay={3000}
                leaveDelay={200}
                enterNextDelay={6000}
            >
            <div
                {...draggableProps}
                {...dragHandleProps}
            >
                <Flipper
                    flipped={snap.private}
                    flipAnimating={false}
                    front={
                        <MDCCard
                            id={"Card_ID_" + snap.id }
                            className={classes.join(' ') + ' face'}
                            onClick={setFocus}
                        >
                            { retired && <RetiredBadge /> }
                            { merit > 0 && <MeritBadge merit={merit}/> }

                            <SnapImage src={src}/>
                            <SnapTitle text={snap.title}/>

                            <CardActions
                                expanded={expanded}
                                tags={snapTags}
                                expandToggle={expandToggle}
                                date={date}
                                retired={retired}
                                deleteAction={() => {
                                    handleDeleteSnap(snap, () => {
                                        updateFocusCard(null)
                                        updateLinkedCard(null)
                                    })
                                }}
                                editAction={() => popSnapFormDialog(
                                    () => onSuccess,
                                    snap
                                )}
                                retireAction={() => handleToggleSnapRetired(snap)}
                                privateAction={togglePrivate}
                                meritAction={incrementMerit}
                            />
                        </MDCCard>
                    }
                    back={
                        <MDCCard
                            id={"Card_ID_" + snap.id }
                            className={classes.join(' ') + ' back'}
                            onClick={togglePrivate}
                        />
                    }
                />
            </div>
        </Tooltip>
    )
}

export const SnapImage = ({src}) =>
    <div className='snapImageFrame'>
        <div className='snapImage' style={{backgroundImage: `url(${src})`}}/>
    </div>

export const SnapTitle = ({text}) => {
    const tl = text.length
    const lengthAdjust = "spacingAndGlyphs"
    let textLength = '260'
    if (tl === 1) textLength = '40'
    if (tl > 1) textLength = '80'
    if (tl > 3) textLength = '100'
    if (tl > 7) textLength = '130'
    if (tl > 14) textLength = '260'

    return <div className='snapTitle'>
        <svg width="100%" height="100%" viewBox='0 0 270 40'>
            <text textLength={textLength} lengthAdjust={lengthAdjust} x='50%' y="30" textAnchor='middle'>{text}</text>
        </svg>
    </div>
}

export default memo(Snap, (prevProps: SnapProps, nextProps: SnapProps) => {
    // Return - is prevProps === nextProps
    // Stringify allows us to compare the objects
    const prevSnap = JSON.stringify(prevProps.snap)
    const nextSnap = JSON.stringify(nextProps.snap)
    if (prevSnap !== nextSnap) return false

    if (prevProps.dragHandleProps !== nextProps.dragHandleProps) return false
    if (prevProps.draggableProps !== nextProps.draggableProps) return false
    if (prevProps.inDialog !== nextProps.inDialog) return false
    if (prevProps.isDragging !== nextProps.isDragging) return false

    // Previous and Next are equal
    return true
})