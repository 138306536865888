import React, {createContext, useContext} from 'react';
import { createSnap, updateSnap, deleteSnap } from '../database/controllers/Snap'
import {notify} from "../Components/Notice"
import ConfirmContext from './Confirm.context'

const SnapContext = createContext();

const SnapProvider = ({ children }) => {
    const {confirm} = useContext(ConfirmContext)

    const handleCreateSnap = (snap, onSuccess) => {
        return createSnap(snap).then((success) => {
            if (success) {
                notify('Snap created')
                if (onSuccess) onSuccess()
            } else {
                notify('Error creating Snap')
            }
        })
    }

    const handleUpdateSnap = (snap, options = {noNotify: false}) => {
        return updateSnap(snap).then((success) => {
            if (options.noNotify) return

            if (success) {
                notify('Snap updated')
            } else {
                notify('Error updating Snap')
            }
        })
    }
    
    const handleDeleteSnap = (snap, onSuccess) => {
        confirm(() => deleteSnap(snap.id).then((success) => {
            if (success) {
                notify('Snap deleted')
                if (onSuccess) onSuccess()
            } else {
                notify('Error deleting Snap')
            }
        }), 'Do you want to delete this Snap?', 'Delete Snap')
    }

    const handleToggleSnapRetired = (snap) => {
        snap.retired_at = snap.retired_at ? null : new Date()

        updateSnap(snap).then((success) => {
            if (success) {
                let message = snap.retired_at ? 'Snap retired' : 'Snap un-retired';
                notify(message)
            } else {
                notify('Error updating Snap')
            }
        })
    }

    const handleToggleSnapPrivate = (snap) => {
        snap.private = !snap.private

        updateSnap(snap)
    }

    const handleUpdateSnapMerit = (snap) => updateSnap(snap)

    return (
        <SnapContext.Provider value={{
            handleCreateSnap,
            handleUpdateSnap,
            handleDeleteSnap,
            handleToggleSnapRetired,
            handleToggleSnapPrivate,
            handleUpdateSnapMerit,
        }}>
            {children}
        </SnapContext.Provider>
    );
};

export {SnapProvider}
export default SnapContext