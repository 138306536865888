import React, {useState} from 'react';
import { CardBack } from '../Helpers/cardBack';

export default function CardBackSelector(props) {

    // Used to re-trigger drawCard animation on new cardBack selection
    const [animationDone, setAnimationDone] = useState(false);
    // Handle animations playing for new cards and allow enabling when changing card backs for editing cards
    const [drawAnimationEnabled, setDrawAnimationEnabled] = useState(!!props.autoDraw);
    const classes = [
        'cardBackMenu',
        !animationDone && drawAnimationEnabled ? 'doDraw' : null,
        animationDone ? 'animationDone': null
    ];
    const backs: CardBack[] = [
        'sakura',
        'choco',
        'festive-melon',
        'retro-stripe',
        'rainy_cafe',
        'sunset',
        'default', // Place the default in the middle
        'bloom',
        'tome',
        'vhs',
        'chains',
        'autumn',
        'cargo',
        'terminal'
    ];
    const [selectedIndex, setSelectedIndex] = useState(backs.indexOf(props.initialCardBack));

    const deck = (number) => {
        let cards = [];
        let i = 0;

        while (i < number) {
            cards.push(<div className={'cardBack deckCard_' + i} key={i}/>);
            i++;
        }

        return cards.reverse();
    };

    // Return amount from bottom to center selected CardBack
    const centerSelectedCardBack = () => {
        const cardBackHeight = 133;
        return cardBackHeight * selectedIndex;
    };

    const selectCardBack = (index) => {
        // Don't replay if already selected
        if (index !== selectedIndex) {
            setSelectedIndex(index);
            setTimeout(() => {
                setDrawAnimationEnabled(true);
                setAnimationDone(false);
            }, 245);

            props.setCardBack(backs[index]);
            props.setUnsavedChanges(true);
        }
    };

    const renderCardBackSelector = (back, i) => {
        const selected = selectedIndex === i ? 'selected' : null;
        const classes = [
            "cardBackSelector",
            back,
            selected
        ];

        return (
            <div
                className={classes.join(' ')}
                key={back}
                onClick={() => selectCardBack(i)}
            >
                { deck(3) }
                <div className='cardBack top'/>
            </div>
        )
    };

    const animationEnd = () => {
        setAnimationDone(true);
    };

    return (
        <div
            className={classes.join(' ')}
        >
            <div
                className={'cardBackFlexWrapper'}
                style={{ bottom: centerSelectedCardBack() }}
            >
                { backs.map(renderCardBackSelector) }
            </div>
            <div className='cardBackMenuTransitionBar'>
                <div
                    className={'cardBackFlipper ' + backs[selectedIndex]}
                    onAnimationEnd={animationEnd}
                >
                    <div className='back'/>
                    <div className='front'/>
                </div>
            </div>
        </div>
    )
}