import React, {useContext} from 'react'
import { Fab, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SnapFormContext from '../Forms/Contexts/SnapForm.context'
import SnapContext from '../../Contexts/Snap.context'
import AppContext from '../../Contexts/App.context'
import {useBackupPrompt} from "../../hooks/useBackupPrompt"

const NewSnapButton = ({cardDrawerOpen = false, bonusAction}: {cardDrawerOpen: boolean, bonusAction?: () => {}}) => {
    const {popSnapFormDialog} = useContext(SnapFormContext)
    const {handleCreateSnap} = useContext(SnapContext)
    const {attemptPersistentStorageGrant} = useContext(AppContext)
    const backupPrompt = useBackupPrompt()

    const onSuccess = (snap) => {
        return handleCreateSnap(snap, () => {
          attemptPersistentStorageGrant()
          backupPrompt()
        })
    }

    return (
        <Tooltip
            title="New Snap"
            placement="left"
            enterDelay={1000}
            leaveDelay={200}
            slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 20],
                      },
                    },
                  ],
                },
              }}
        >
            <div className={"NewSnapButton" + (cardDrawerOpen ? ' cardDrawerOpen' : '')}>
                <Fab
                    variant="circular"
                    color="primary"
                    aria-label="New Snap"
                    className="NewSnapButtonFab"
                    onClick={() => {
                      popSnapFormDialog(() => onSuccess)
                      if (bonusAction) bonusAction()
                    }}
                >
                    <AddIcon />
                </Fab>
                <div className='SnapAnimation'>
                  <div className='SnapOuter'/>
                  <div className='SnapInner'/>
                </div>
            </div>
        </Tooltip>
    )
}

export default NewSnapButton