import Dexie, {Table} from 'dexie'
import { Journey } from './models/Journey'
import { ListOrder } from './models/ListOrder'
import { Snap } from './models/Snap'

export class Database extends Dexie {
    // Typing
    journeys!: Table<Journey>
    listOrders!: Table<ListOrder>
    snaps!: Table<Snap>

    constructor() {
        super('noto') // Database name

        this.version(1).stores({
            journeys: '++id', // ONLY Primary key and indexed props (https://dexie.org/docs/API-Reference#declare-database)
            listOrders: 'listName' // To handle arbitrary UI sorting for lists - eg: Collections, Journeys - only one entry per type - listName is Unique (https://dexie.org/docs/API-Reference#quick-reference)
        })

        this.version(2).stores({
            snaps: '++id, title'
        })
    }
}
  
export const db = new Database()