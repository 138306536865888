import React, {useContext} from 'react'
import AppContext from '../../Contexts/App.context.js'
import {CardsOrnate} from "../Cards"
import {searchCards} from "../../Helpers/cardSearch"
import Dialog from '@mui/material/Dialog'
import Input from '@mui/material/Input'
import { useSearchTerm } from '../../hooks/useSearchTerm'

const MemoizedTemplateSelectField = React.memo(TemplateSelectField, propsAreEqual)

function propsAreEqual(prev, next) {
    return prev.open === next.open
}

function TemplateSelectField({open, setOpen, insertTemplate}) {
    const {allCards} = useContext(AppContext)
    const [searchTerm, updateSearchTerm, setSearchTerm] = useSearchTerm()

    const handleClose = () => {
        setOpen(false)
        setSearchTerm('')
    }

    const templateCards = allCards.filter(x => x.is_template && !x.private)
    
    return (
        <Dialog open={open} onClose={handleClose} className={'CardSelectFieldDialog'}>
            <div className={'CardSelectField'}>
                <CardsOrnate
                    cards={searchCards(templateCards, searchTerm)}
                    onClick={(card) => {
                        insertTemplate(card.id)
                        handleClose()
                    }}
                />
            </div>
            <Input
                className={"TemplateSelectSearch"}
                type="text"
                placeholder="Search..."
                onChange={(event) => updateSearchTerm(event.target.value)}
                autoFocus
            />
        </Dialog>
    )
}

export default MemoizedTemplateSelectField