import React, { ReactElement } from 'react';

type FlipperProps = {
    flipped: boolean
    flipAnimating: boolean
    front: ReactElement
    back: ReactElement
}

export default function Flipper({flipped, flipAnimating, front, back}: FlipperProps) {
    const flipperClasses = [
        "flipper",
        flipped ? 'flipped' : null,
        flipAnimating ? 'flipAnimating' : null
    ];
    
    return (
        <div className={flipperClasses.join(' ')}>
            <div className="flipperInner">
                <div className="flipperFace">
                    {front}
                </div>
                <div className="flipperBack">
                    {back}
                </div>
            </div>
        </div>
    )
}