import React, {useContext, useState} from 'react'

import CollectionOverviewTopBar from './Collections/CollectionOverviewTopBar'
import CollectionTopBar from './Collections/CollectionTopBar'
import Cards from '../Cards'
import {collectCards, saveCollections, deleteCollection} from '../../Structures/Collection'
import {Collections} from "./Collections/Collections"
import {notify} from "../Notice"
import {searchCards, searchCollections} from "../../Helpers/cardSearch"
import AppContext from "../../Contexts/App.context.js"
import {CollectionFormProvider} from "../Forms/Contexts/CollectionForm.context"
import ConfirmContext from "../../Contexts/Confirm.context"
import NewCardButton from '../Universal/NewCardButton'
import NewSnapButton from '../Universal/NewSnapButton'
import { useSearchTerm } from '../../hooks/useSearchTerm'
import {Style} from '@mui/icons-material'
import { EmptyStatePrompt } from '../Universal/EmptyStatePrompt'
import { EmptyMessage } from '../../Helpers/tools'
import { useBackupPrompt } from '../../hooks/useBackupPrompt'
import NoteFormContext from '../Forms/Contexts/NoteForm.context'

function CollectionScreen() {
    const {collections, setCollections, screen, updateScreen, allCards, tags, loadCards, attemptPersistentStorageGrant} = useContext(AppContext)
    const {confirm} = useContext(ConfirmContext)
    const {popNoteFormDialog} = useContext(NoteFormContext)
    const backupPrompt = useBackupPrompt()

    const [currentCollection, setCurrentCollection] = useState(null)
    const [searchTerm, updateSearchTerm, setSearchTerm] = useSearchTerm()

    // react-beautiful-dnd Reorder function
    const reorder = (list, startIndex, endIndex) => {
        const removed = list.splice(startIndex, 1)
        list.splice(endIndex, 0, removed[0])
        return list
    }

    //<editor-fold desc="Collections Ordering">
    // Update collections after dragging and dropping
    // (react-beautiful-dnd function)
    // passed to CardOverviewTopBar
    const rearrangeCollection = (result) => {
        // Dropped outside the list OR not moved from original position
        if (!result.destination || result.source.index === result.destination.index) {
            return
        }

        const reorderedCollections = reorder(
            collections.slice(),
            result.source.index,
            result.destination.index
        )

        // Update state
        setCollections(reorderedCollections)
        // Save to storage
        saveCollections(reorderedCollections)
    }
    //</editor-fold>

    //<editor-fold desc="Collections">

    // Add/Update supplied collection to storage and state
    //
    // @param collection {object} Collection object
    //
    // @returns {Promise}
    const saveCollection = (collection) => {
        let updatedCollections = collections.slice()

        let updateIndex = -1
        if (collection.ID !== null) {
            // Update collection
            updateIndex = updatedCollections.indexOf(
                // Find collection by ID
                updatedCollections.find((i) => {
                    return i.ID === collection.ID
                })
            )
        }

        // If updateIndex found
        if (updateIndex > -1) {
            // Replace with updated one
            updatedCollections.splice(updateIndex, 1, collection)
        } else {
            // Add collection
            updatedCollections.push(collection)
        }

        // Update state
        setCollections(updatedCollections)

        return saveCollections(updatedCollections).then(() => {
            // Primarily used to refresh tags
            loadCards()
        })
    }

    // Delete collection from storage and state
    //
    // @param collection {object} Collection object
    const handleDeleteCollection = (collection) => {
        confirm(() => {
            deleteCollection(collection.ID).then((collections) => {
                notify('Collection deleted')

                // Update state
                setCollections(collections)
            })

        }, 'Do you want to delete this collection?', 'Delete Collection')
    }

    // Switch collection display on/off
    //
    // @param index {int} Index of collection to update
    //
    // returns {Promise}
    const toggleCollectionDisplay = (index) => {
        // Get collections
        let updatedCollections = collections.slice()
        // Toggle display of specified collection
        updatedCollections[index].display = !updatedCollections[index].display

        // Update state
        setCollections(updatedCollections)

        return saveCollections(updatedCollections)
    }

    // Show contents of collection
    //
    // @param id {int} ID of collection to open
    const openCollection = (id) => {
        // Get collection by ID
        let collection = collections.find(function(collection) {
            return collection.ID === id
        })

        // Set state currentCollection
        setCurrentCollection(collection)

        // Set screen
        updateScreen('collection')
    }

    // Close collection (go back to overview screen)
    const closeCollection = () => {
        setCurrentCollection(null)
        setSearchTerm('')

        // Reset screen
        updateScreen('collections')
    }
    //</editor-fold>

    // Collection (current)
    // Show a single ('currently open') collection
    const collectionView = () => {
        // Show collection contents, ensure collection is made immutable
        const collection = Object.assign(currentCollection)
        const collectionCards = searchCards(collectCards(allCards, collection), searchTerm)

        const noCards = collectionCards.length === 0
        const emptyStatePrompt = noCards && !searchTerm
        const searchEmptyState = noCards && searchTerm

        const onSuccess = () => {
            loadCards()
            attemptPersistentStorageGrant()
            backupPrompt()
        }

        const renderCards = () => {
            if (emptyStatePrompt) return <EmptyStatePrompt
                icon={<Style style={{transform: "rotate(180deg)"}}/>}
                message="Let's create a Card for this Collection!"
                onClick={() => {popNoteFormDialog(() => onSuccess, null, collection)}}
            />

            if (searchEmptyState) return <EmptyMessage />
            
            return <Cards
                cards={collectionCards}
                collection={collection}
                tags={tags.slice()}
            />
        }

        return (
            <React.Fragment>
                <CollectionTopBar
                    collection={collection}
                    close={closeCollection}
                    searchCards={updateSearchTerm}
                />
                { renderCards() }
            </React.Fragment>
        )
    }

    // Collection overview
    // Main screen output
    const collectionOverview = () => {
        if (currentCollection === null || screen === 'collections') {

            return (
                <React.Fragment>
                    <CollectionOverviewTopBar
                        collections={collections.slice()}
                        tags={tags.slice()}
                        rearrangeCollection={rearrangeCollection}
                        saveCollection={() => saveCollection}
                        deleteCollection={handleDeleteCollection}
                        toggleCollectionDisplay={toggleCollectionDisplay}

                        searchCards={updateSearchTerm}
                    />
                    <Collections
                        collections={searchCollections(collections.slice(), searchTerm)}
                        cards={allCards.slice()}
                        tags={tags.slice()}
                        collectCards={collectCards}
                        open={openCollection}
                        saveCollection={() => saveCollection}
                        searchTerm={searchTerm}
                    />
                </React.Fragment>
            )
        }
    }

    // What gets rendered
    let output = collectionOverview()

    // Collection
    let collection = null
    if (currentCollection !== null && screen === 'collection') {
        output = collectionView()
        collection = Object.assign(currentCollection)
    }

    return (
        <div className="Screen">
            <CollectionFormProvider>
                {output}

                <NewSnapButton/>
                <NewCardButton collection={collection}/>
            </CollectionFormProvider>
        </div>
    )
}

export default CollectionScreen