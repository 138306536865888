import localforage from "localforage";
import {getCards} from "./Card";
import {Collection, loadCollections} from "./Collection";
import { db } from "../database/db";
import { Note } from "../database/models/Note";
import { Snap } from "../database/models/Snap";
import { Journey } from "../database/models/Journey";

export type TagType = {
    tag: string
    color: string
}

// <editor-fold desc="Tag">
// A data structure for tags so that tags stored with Cards may act as a reference/ID
// to a Tag and additional data can be added to a Tag
export class Tag {
    tag: string // Tag name, acts as the name and like an ID
    color: string // A Color String (Hex, RGB, RGBA)

    constructor(tag, color = null) {
        this.tag = tag;
        this.color = color ? color : '#A9A9A9';
    }
}

// Save array of Tags to storage
//
// @param tags {array} of Tag objects
//
// @returns Promise
export function saveTags(tags) {
    if (!Array.isArray(tags)) {
        throw new Error('Supplied parameter "tags" is not an array.');
    }

    // Loads existing tags
    // By using getActiveTags instead of loadTags we will delete inactive tags on save
    return getActiveTags().then((storedTags) => {
        let updatedTags = storedTags;

        tags.forEach(function(tag) {
            // Only consider saving Tags with a tag
            if (tag.tag) {
                // If tag not already present
                const index = storedTags.findIndex((t) => {
                    return t.tag === tag.tag
                });
                // If tag exists
                if (index > -1) {
                    // Update tag
                    updatedTags.splice(index, 1, tag);
                } else { // Tag doesn't exist
                    // Add tag
                    updatedTags.push(tag);
                }
            }

            // Save updatedTags
            return localforage.setItem('tags', updatedTags).catch(function(err) {
                throw new Error(err);
            });
        });
    });
}

// Get array of Tags from storage
//
// @returns Promise {array} of Tag objects
export function loadTags() {
    return localforage.getItem('tags').then((result) => {
        // Make sure an array is returned
        if (Array.isArray(result)) {
            return result;
        }
        return [];
    }).catch(function(err) {
        console.log(err);
        return [];
    });
}

// Get all currently used tags
//
// @returns {Promise -> Array} Promise to getCards and evaluate to Array of active Tags
// This function requests all sources of Tags being stored (Cards, Collections, Snaps)
// and accumulates their tags under a concept called "Active Tags"
//
// Active tags are important because they mean that we remove/clean-out tags that don't
// have a usage anywhere
// Important - This means that if this function is not accurately representing all active Tags
// we will likely end up deleting those that are missed in being collected here
export function getActiveTags() {
    // Await resolution of promises to get Cards and Collections
    return Promise.all([
        getCards(),
        db.snaps.toArray(),
        loadCollections(),
        db.journeys.toArray()
    ])
    .then((values) => {
        let cards: Note[] = values[0]
        let snaps: Snap[] = values[1]
        let collections: Collection[] = values[2]
        let journeys: Journey[] = values[3]
        
        const allCards = [...cards, ...snaps]
        const collectionsAndJourneys = [...collections, ...journeys]

        return tagData(allCards, collectionsAndJourneys)
    })
}

// Get Tag data for provided Cards (save fetching Cards)
//
// @param cards {array} of Cards - Support all Generic Cards (eg: Note|Snap)
// @param collections (optional) {array} of Collections
//
// @returns {Promise -> Array} Promise to loadTags and evaluate to Array of active Tags
// ---
// Active tags is a mechanism that allows simplifying the tag management process
// such that deletion is not required - this is automatic in the lifecycle if a tag is not used
// ---
export function tagData(allCards: (Note|Snap)[], collections: (Collection|Journey)[] = null) {
    let activeTags = [];

    // Get tags
    return loadTags().then(tags => {
        // Add to activeTags if not already there
        const checkThenAdd = (tagString) => {
            // If tag not already present
            if (activeTags.filter(t => t.tag === tagString).length === 0) {
                // Find saved Tag object if exists in storage
                let savedTag = tags.filter(item => {return item.tag === tagString})[0];
                const color = savedTag?.color ?? null;
                // Add tag
                activeTags.push(new Tag(tagString, color));
            }
        };

        // Loop allCards
        allCards.forEach(card => {
            // Loop tags
            card.tags.forEach(tag => {
                checkThenAdd(tag);
            });
        });

        if (collections) {
            // Loop collections
            collections.forEach(collection => {
                // Loop filters
                collection.filters.forEach(filter => {
                    checkThenAdd(filter.label);
                });
            });
        }

        return activeTags;
    });
}

// Attach data from Tag to the Tag reference on the Card
// - Tags are stored as their own data type, whereas a Card just references Tags
//  by name. This function merges them together for easy processing and output
//
// @param tagDatas {Array} of Tags
// @param cardTags {Array} of tag names from a Card
//
// @return {Array} of Tag classes
//
export function tagDataForCard(tagDatas, cardTags): Tag[] {
    tagDatas = tagDatas.slice();
    cardTags = cardTags.slice();

    return cardTags.map((cardTag) => {
        // Match tagDatas to card tag string
        let tagData = tagDatas.filter((tg) => {return tg.tag === cardTag;})[0];
        const color = tagData && tagData.color ? tagData.color : null;

        return new Tag(cardTag, color);
    });
}

// Attach data from Tag to Filter (of a Collection)
// - This function is very similar to tagDataForCard()
//
// @param tagDatas {Array} of Tags
// @param filters {Array} of filters (of a Collection)
//
// @return {Array} of Filters with color appended
export function tagDataForFilter(tagDatas = [], filters = []) {
    tagDatas = tagDatas.slice();
    filters = filters.slice();

    return filters.map((filter) => {
        // Match tagDatas to filter tag string
        let tagData = tagDatas.filter(tg => tg.tag === filter.label)[0];
        // Set color
        filter.color = tagData && tagData.color ? tagData.color : null;

        return filter;
    })
}