import React, {useContext} from 'react'
import AppContext from '../../Contexts/App.context.js'
import {cardValueLabel} from '../CardValue.js'

// Supports a number of id attributes
// card_id - Archaic attr before Snaps were added, introducing two types - remains for backwards compatibilty of existing data
// note_id/snap_id - Newly generated CardLinks after Snap type introcuded
export default function CardLink({card_id, note_id, snap_id, content}) {
    const {allCards, linkedCard, updateLinkedCard} = useContext(AppContext)
    // Backwards compat.
    let id = card_id
    let type = 'note'
    // Note
    if (note_id) {
        id = note_id
    }
    // Snap
    if (snap_id) {
        id = snap_id
        type = 'snap'
    }
    const cardLink = allCards.find(card => card.id === parseInt(id) && card.type === type)

    // Prevent errors if Card doesn't exist; like when it's been deleted
    if (!cardLink) return <button className={'CardLink deadLink'}>{ content }</button>

    const action = () => {
        updateLinkedCard(cardLink)
    }

    const isActive = !linkedCard.cleared && linkedCard.card && parseInt(id) === linkedCard.card.id && type === linkedCard.card.type

    const classes = [
        'CardLink',
        isActive ? 'active' : null,
        !content ? 'empty' : null,
        cardValueLabel(cardLink.merit),
        cardLink.back
    ]
    
    return <button className={classes.join(' ')} onClick={action}>{ content }</button>
}