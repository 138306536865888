import { getRandomInt } from "../../Helpers/tools"

export interface Card {
    id?: number
    tags: string[]
    merit: number
    private: boolean
    retired_at?: Date
    created_at: Date
    updated_at?: Date
    deleted_at?: Date
    back: string
    wear: number
    wear_variant: number
    type: 'note' | 'snap'
}

// The Card class serves as a base class for similar Card type data
// Includes Cards(Notes), Snaps

// TODO: The Snap class will implement this base class, but the Card/Note will not yet
// as there are some key differences like id/ID in the archaic structur that need to be
// migrated

// TODO: This class is currently built in a similar structure to the Dexie DB
// classes but, Cards are currently on the archaic LocalDB infra - we'll change this
// in the future moving Cards onto the same structure as Snaps and Journeys
export class CardClass implements Card {
    id?: number
    tags: string[]
    merit: number
    private: boolean
    retired_at?: Date
    created_at: Date
    updated_at?: Date
    deleted_at?: Date
    back: string
    wear: number
    wear_variant: number
    type: 'note' | 'snap'
    
    constructor() {
        this.tags = []
        this.merit = 0
        this.private = false
        this.retired_at = null
        this.created_at = new Date()
        this.updated_at = null
        this.back = null
        this.wear = 0
        this.wear_variant = getRandomInt(4)
    }
}