import React, {createContext, useState, useEffect, useContext} from 'react'
import {getCards as getCardData} from "../Structures/Card"
import {loadCollections} from "../Structures/Collection"
import {tagData} from "../Structures/Tag"
import ConfigContext from "./Config.context"
import SnapContext from "./Snap.context"
import {usePersistentStorage} from "../hooks/usePersistentStorage"
import { urlToDialogue } from '../Helpers/url'

import {db} from '../database/db'
import {useLiveQuery} from 'dexie-react-hooks'

// App Context
//
// This Context is intended to provide global app data and functions to Components
// deeper in the applications without 'prop drilling'
//
// React Docs for Context: https://reactjs.org/docs/context.html, https://reactjs.org/docs/context.html#classcontexttype
const AppContext = createContext();

const AppProvider = ({children}) => {
    const loading = 'loading'

    const {config} = useContext(ConfigContext);
    const {handleUpdateSnap} = useContext(SnapContext)

    const [screen, setScreen] = useState('cards');
    const [cards, setCards] = useState(loading);
    const [tags, setTags] = useState([]);
    const [collections, setCollections] = useState([]); // Collections state was hoisted up to App because it became related to tags (a tag can exist on a Collection and not on a Card)
    const [initialLoadEmpty, setInitialLoadEmpty] = useState(loading); // This determines if the introduction is shown
    const [focusCard, setFocusCard] = useState(null); // The Card in focus
    const [linkedCard, setLinkedCard] = useState({
        'card': null,
        'cleared': false
    }); // The current active Card referenced by CardLink
    const [allCards, setAllCards] = useState(loading)

    const [urlDialogue, setUrlDialogue] = useState(null)

    const snaps = useLiveQuery(() => db.snaps.reverse().toArray(), [], loading)
    const journeys = useLiveQuery(() => db.journeys.toArray(), [], loading)

    const {attemptPersistentStorageGrant} = usePersistentStorage();

    // Provided as prop to allow setting screen deeper in application
    const updateScreen = (s) => {
        window.scrollTo(0, 0);
        setScreen(s);
    };

    // @param card {Card || null}
    const updateFocusCard = (card) => {
        setFocusCard(card);
        // Increment card wear with each focus if enabled
        if (card && config.cardWear) {
            card.wear += 1;

            if (card.type === 'note') {
                card.save()
            }
            if (card.type === 'snap') {
                handleUpdateSnap(card, {noNotify: true})
            }
        }
    };

    const cardByID = (id) => {
        try {
            return allCards.filter(x => x.id === id && x.type === 'note')[0]
        } catch {
            throw new Error(`Card not found by id:${id}`)
        }
    };

    const updateLinkedCard = (card, cleared = false) => {
        // Use this object approach to carry more information
        setLinkedCard({
            'card': card,
            'cleared': cleared
        });
    }

    // Manage allCards state
    // allCards is managed in state to allow proper lifecycle management
    useEffect(() => {
        // Await all loaded state
        if (cards === loading || snaps === loading) return

        // Set type metadata
        // NOTE: This should not be stored as it is for reference only by the frontend
        // by summoning this information we know the type, that's where we will assign this metadata
        if (snaps !== loading) snaps.map(s => s.type = 'snap')

        // Cards + Snaps in created_at DESC order
        const snapsArray = snaps !== loading ? snaps : []
        const combinedCardsArray = [...cards, ...snapsArray].sort((a, b) => {
            const dateA = new Date(a.created_at)
            const dateB = new Date(b.created_at)
            return dateB - dateA
        })

        setAllCards(combinedCardsArray)
        
    }, [cards, snaps]);

    useEffect(() => {
        // Cards loading finished
        if (allCards !== loading) {
            // This is only for initial load, only do it once
            const isInitialLoad = initialLoadEmpty === loading
            if (isInitialLoad) {
                const isEmpty = allCards.length === 0
                setInitialLoadEmpty(isEmpty)
            }
        }
    }, [allCards, initialLoadEmpty])

    // Loading tags is now affected by Cards and Snaps
    // So we use a useEffect to load/reload tags when changes occur
    useEffect(() => {
        // Wait for initial load of all
        if (allCards === loading || journeys === loading) return

        // Load Tags
        loadTags()
        
    }, [allCards, collections, journeys]) // Load tags when any Tag bearing entity changes

    // Load Cards into state
    //
    // @returns {Promise -> Array} Promise of Array of cards
    const loadCards = () => {
        // Get cards using imported method from Structures/Card.js
        // Get collections using imported method from Structures/Collection.js
        return Promise.all([
            getCardData(),
            loadCollections()
        ]).then((values) => {
            let loadedCards = values[0];
            let loadedCollections = values[1];

            // Update state
            setCards(loadedCards);
            setCollections(loadedCollections);

            return loadedCards;

        }).catch(function(err) {
            console.log(err);
            return [];
        });
    };

    // Load Tags into state
    //
    // @returns void
    const loadTags = () => {
        // Get tags using imported method from Structures/Tag.js
        const collectionsAndJourneys = [...collections, ...journeys]
        return tagData(allCards, collectionsAndJourneys).then((loadedTags) => {
            // Update state
            setTags(loadedTags)
        }).catch(function(err) {
            console.log(err)
            return []
        })
    }

    useEffect(() => {
        // Load App Cards (and Tags by useEffect)
        loadCards()
    }, [])
    
    useEffect(() => {
        // Check for URL intended dialogue
        setUrlDialogue(urlToDialogue())
    }, [])

    return (
        <AppContext.Provider value={{
            screen,
            updateScreen,
            allCards: allCards === loading ? [] : allCards,
            tags,
            journeys,
            collections,
            setCollections,
            focusCard,
            linkedCard,
            initialLoadEmpty: initialLoadEmpty === loading ? false : initialLoadEmpty,
            loadCards,
            updateFocusCard,
            updateLinkedCard,
            cardByID,
            attemptPersistentStorageGrant,
            urlDialogue
        }}>
            {children}
        </AppContext.Provider>
    )
}

export { AppProvider }
export default AppContext